import * as React from "react";

const LogoMaster = ({ className }) => {
  return (
    <div className={`logo-master ${className}`}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 421 421"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="210.5" cy="210.5" r="210.5" fill="#FF4522" />
        <g className="letters">
          <path
            d="M49.5795 199.123C47.7406 200.588 45.4137 201.227 42.599 201.039C41.9235 201.002 40.8351 200.889 39.3715 200.739C37.8703 200.588 36.8195 200.438 36.1439 200.363C33.3667 199.987 31.2275 198.86 29.8014 197.019C28.3378 195.178 27.7748 192.774 28.1126 189.805C28.3378 187.777 28.8632 186.123 29.7639 184.808C30.6646 183.493 31.6404 182.517 32.7287 181.915C33.8171 181.314 34.7929 181.051 35.6936 181.089H35.7686C35.9563 181.126 36.1064 181.202 36.219 181.352C36.3316 181.502 36.3691 181.69 36.3691 181.878L36.1439 183.794C36.1064 183.982 36.0689 184.132 35.9563 184.245C35.8437 184.32 35.656 184.433 35.3933 184.47C34.2674 184.733 33.2917 185.297 32.466 186.199C31.6404 187.1 31.1149 188.378 30.9273 190.068C30.7021 191.985 31.0774 193.563 32.0157 194.765C32.9539 195.967 34.5301 196.719 36.7069 197.057C37.3824 197.169 38.3957 197.282 39.7468 197.432C41.0978 197.583 42.1111 197.658 42.8242 197.733C45.0384 197.921 46.7648 197.508 47.9282 196.493C49.0916 195.516 49.8047 194.051 49.9924 192.135C50.2175 190.181 49.8422 188.566 48.904 187.288C47.9658 186.011 46.427 185.259 44.2879 185.034L42.8242 184.884L42.2612 190.144C42.2237 190.369 42.1487 190.557 41.961 190.67C41.7734 190.82 41.5857 190.857 41.3605 190.82L40.3472 190.707C40.1221 190.67 39.9344 190.594 39.7843 190.407C39.6342 190.256 39.5966 190.031 39.5966 189.805L40.4223 182.103C40.4598 181.84 40.5349 181.652 40.7225 181.54C40.8727 181.389 41.0978 181.352 41.3605 181.389L44.6256 181.728C46.4646 181.915 48.0783 182.479 49.3919 183.418C50.7054 184.32 51.6812 185.56 52.2817 187.138C52.8821 188.678 53.0698 190.444 52.8821 192.435C52.4693 195.441 51.4185 197.658 49.5795 199.123Z"
            fill="#68035E"
          />
          <path
            d="M54.4208 177.144C53.7453 177.97 52.9571 178.572 52.0564 178.91C51.1557 179.285 50.2175 179.323 49.2792 179.098C47.8156 178.759 46.7272 177.858 46.0892 176.392C45.4512 174.965 45.226 173.161 45.4137 170.982L45.9391 165.76L44.9633 165.534C42.674 164.971 41.1728 166.023 40.5348 168.69C40.2721 169.705 40.3096 170.606 40.5723 171.32C40.8351 172.034 41.2104 172.635 41.6982 173.049C41.8859 173.199 42.036 173.312 42.0735 173.462C42.1111 173.575 42.1486 173.725 42.0735 173.913L41.7733 175.228C41.7358 175.416 41.6232 175.603 41.4355 175.679C41.2479 175.791 41.0602 175.829 40.8726 175.754C40.3472 175.641 39.8218 175.228 39.2588 174.589C38.6959 173.95 38.283 173.049 38.0203 171.921C37.7576 170.794 37.7952 169.517 38.1705 168.052C38.7709 165.534 39.7842 163.881 41.2104 163.092C42.6365 162.303 44.2127 162.078 45.9766 162.529L56.8977 165.159C57.1229 165.196 57.273 165.346 57.3856 165.497C57.4982 165.685 57.5357 165.872 57.4607 166.098L57.1229 167.563C57.0854 167.789 56.9353 167.939 56.7852 168.052C56.5975 168.164 56.4099 168.164 56.1847 168.127L54.721 167.789C55.359 168.465 55.7719 169.329 56.0346 170.381C56.2973 171.433 56.2597 172.71 55.8844 174.176C55.5842 175.378 55.0963 176.317 54.4208 177.144ZM52.9571 169.028C52.2065 167.864 50.9305 167.037 49.0916 166.586L48.1533 166.361L47.778 170.419C47.6279 172.072 47.703 173.387 48.0407 174.364C48.3785 175.34 48.9415 175.904 49.7671 176.092C50.6678 176.317 51.4935 176.092 52.169 175.491C52.8445 174.852 53.2949 174.025 53.5576 173.011C53.8954 171.508 53.7077 170.193 52.9571 169.028Z"
            fill="#68035E"
          />
          <path
            d="M57.761 157.607L59.1496 158.133C59.3748 158.208 59.5249 158.358 59.6 158.546C59.675 158.734 59.675 158.959 59.6 159.147L59.0746 160.5C58.9995 160.725 58.8494 160.875 58.6617 160.951C58.4741 161.026 58.2489 161.026 58.0613 160.951L36.6318 152.948C36.4066 152.873 36.219 152.722 36.1439 152.535C36.0689 152.347 36.0689 152.159 36.1439 151.933L36.7069 150.468C36.7819 150.243 36.9321 150.092 37.1197 150.017C37.3074 149.942 37.5325 149.942 37.7202 150.017L45.1886 152.798C44.0627 150.994 43.9126 148.928 44.7382 146.673C45.6014 144.419 46.9525 142.991 48.8665 142.39C50.7805 141.827 52.8446 141.902 55.0964 142.653C55.3591 142.729 55.6594 142.841 56.0722 142.991C56.485 143.142 56.7852 143.292 57.048 143.405C59.2622 144.306 60.876 145.584 61.9268 147.275C62.9776 148.965 63.0902 150.919 62.227 153.211C61.3639 155.503 59.8627 156.968 57.761 157.607ZM59.2997 148.89C58.5867 147.801 57.4608 146.936 55.9221 146.298C55.6969 146.185 55.3967 146.072 54.9838 145.922C50.8556 144.382 48.191 145.133 47.0651 148.214C46.5021 149.679 46.5772 150.994 47.2527 152.121C47.9282 153.286 48.904 154.113 50.2176 154.639C50.4427 154.751 50.8556 154.902 51.456 155.127C52.0565 155.352 52.4693 155.503 52.6945 155.54C54.0831 156.029 55.4342 156.066 56.7102 155.653C57.9862 155.277 58.9244 154.3 59.4874 152.798C60.0503 151.295 60.0128 149.98 59.2997 148.89Z"
            fill="#68035E"
          />
          <path
            d="M66.0926 142.466C65.9049 142.541 65.6797 142.503 65.4921 142.428L50.8555 135.177C50.6679 135.064 50.5178 134.914 50.4427 134.726C50.3676 134.538 50.3676 134.313 50.4802 134.125L51.1182 132.81C51.2308 132.584 51.3809 132.434 51.6061 132.359C51.7938 132.284 52.0189 132.321 52.2441 132.434L53.5952 133.11C52.6194 131.57 52.6194 129.766 53.6327 127.775L54.2332 126.573C54.3458 126.347 54.4959 126.197 54.6835 126.122C54.8712 126.047 55.0588 126.084 55.284 126.197L56.4099 126.761C56.5976 126.873 56.7477 127.024 56.8227 127.212C56.8978 127.399 56.8603 127.625 56.7477 127.85L55.8845 129.616C55.3215 130.743 55.209 131.795 55.5467 132.772C55.8845 133.749 56.6351 134.538 57.761 135.102L66.8807 139.648C67.0683 139.76 67.2184 139.911 67.2935 140.136C67.3686 140.362 67.331 140.549 67.2184 140.775L66.5429 142.165C66.4678 142.24 66.3177 142.39 66.0926 142.466Z"
            fill="#68035E"
          />
          <path
            d="M53.3325 120.787C53.1449 120.824 52.9197 120.824 52.732 120.712L51.1933 119.81C51.0057 119.697 50.8556 119.547 50.7805 119.322C50.7054 119.096 50.743 118.908 50.8556 118.72L51.8689 116.955C51.9815 116.767 52.1691 116.616 52.3568 116.579C52.5819 116.504 52.7696 116.541 52.9948 116.654L54.5335 117.556C54.7211 117.668 54.8712 117.819 54.9088 118.044C54.9463 118.27 54.9088 118.457 54.7962 118.683L53.7829 120.449C53.6703 120.599 53.5202 120.749 53.3325 120.787ZM71.9097 131.269C71.722 131.307 71.4969 131.307 71.3092 131.194L57.1605 122.966C56.9729 122.853 56.8228 122.703 56.7852 122.478C56.7477 122.29 56.7477 122.064 56.8603 121.876L57.6109 120.561C57.7235 120.374 57.8736 120.223 58.0988 120.186C58.2864 120.148 58.5116 120.148 58.6993 120.261L72.8479 128.489C73.0356 128.602 73.1857 128.752 73.2232 128.977C73.2608 129.165 73.2608 129.391 73.1482 129.579L72.3976 130.894C72.285 131.081 72.1349 131.194 71.9097 131.269Z"
            fill="#68035E"
          />
          <path
            d="M75.2124 122.515C73.2233 122.816 71.1216 122.327 68.9825 120.975L68.0067 120.336L67.106 119.66C65.1544 118.119 63.9535 116.353 63.5407 114.362C63.1278 112.371 63.6157 110.417 64.9293 108.463C66.3929 106.397 68.1568 105.232 70.296 105.007C72.3976 104.781 74.6119 105.458 76.8637 107.036L77.3891 107.374C77.5767 107.524 77.6893 107.674 77.7269 107.9C77.7644 108.125 77.7269 108.313 77.5767 108.501L70.7088 118.345L70.9715 118.533C72.2475 119.322 73.5611 119.697 74.9121 119.585C76.2632 119.509 77.3516 118.871 78.1397 117.706C78.7402 116.804 79.0779 115.978 79.1155 115.189C79.153 114.4 79.0779 113.723 78.8903 113.16C78.7777 112.822 78.7402 112.596 78.7402 112.484C78.7402 112.371 78.8527 112.183 79.0029 111.958L79.866 110.718C79.9786 110.53 80.1288 110.417 80.3164 110.38C80.504 110.304 80.6542 110.342 80.8043 110.455C81.2171 110.755 81.5549 111.356 81.7801 112.258C82.0052 113.16 82.0052 114.249 81.7801 115.489C81.5549 116.729 81.0295 117.931 80.1663 119.134C78.8903 121.087 77.239 122.215 75.2124 122.515ZM74.3117 109.102L74.2366 109.027C72.9231 108.088 71.572 107.712 70.2585 107.862C68.9449 108.013 67.8566 108.689 66.9934 109.891C66.1302 111.131 65.8675 112.371 66.2428 113.648C66.5806 114.926 67.4062 116.015 68.7573 116.955L68.8323 117.03L74.3117 109.102Z"
            fill="#68035E"
          />
          <path
            d="M86.9966 109.252C86.7714 109.29 86.5838 109.215 86.4337 109.065L68.7947 94.4117C68.6071 94.2615 68.4945 94.0736 68.4945 93.8482C68.4945 93.6603 68.5696 93.4725 68.6822 93.2846L69.6579 92.1199C69.808 91.932 69.9957 91.8569 70.1833 91.8193C70.4085 91.7817 70.5962 91.8569 70.7463 92.0072L88.3852 106.66C88.5729 106.81 88.6479 106.998 88.6855 107.186C88.723 107.411 88.6479 107.599 88.4978 107.787L87.522 108.952C87.3719 109.14 87.1843 109.252 86.9966 109.252Z"
            fill="#68035E"
          />
          <path
            d="M93.0388 101.964C92.0255 102.339 91.0497 102.414 90.1115 102.264C89.1357 102.114 88.31 101.701 87.6345 101.024C86.5461 99.9723 86.0958 98.6573 86.2834 97.0793C86.4711 95.5013 87.1841 93.8482 88.4602 92.0823L91.5751 87.8743L90.862 87.1605C89.1732 85.5073 87.3718 85.6576 85.4578 87.6113C84.7072 88.3627 84.2568 89.1142 84.1442 89.9032C83.9941 90.6546 84.0317 91.3685 84.2193 91.9696C84.3319 92.195 84.3694 92.3829 84.3319 92.4956C84.3319 92.6083 84.2568 92.7586 84.1067 92.8713L83.1685 93.8482C83.0184 93.9984 82.8307 94.0736 82.6431 94.0736C82.4179 94.0736 82.2678 93.9984 82.1176 93.8482C81.7423 93.4724 81.4796 92.8713 81.3295 92.0072C81.1794 91.143 81.292 90.2037 81.6298 89.0766C81.9675 87.987 82.6806 86.8975 83.7314 85.8079C85.5328 83.9669 87.2592 83.0652 88.9105 83.1028C90.5618 83.1403 91.9879 83.779 93.3015 85.0565L101.333 92.9089C101.483 93.0592 101.596 93.247 101.596 93.4724C101.596 93.6979 101.52 93.8857 101.37 94.036L100.32 95.1256C100.169 95.2759 99.9818 95.351 99.7566 95.351C99.5689 95.351 99.3813 95.2759 99.1936 95.088L98.1053 94.036C98.2929 94.9377 98.2179 95.9146 97.9176 96.929C97.6174 97.981 96.9043 99.033 95.8535 100.123C94.9153 101.024 94.0145 101.625 93.0388 101.964ZM95.9661 94.2614C95.9286 92.8713 95.253 91.5187 93.902 90.2037L93.2264 89.5275L90.8245 92.8337C89.8112 94.1863 89.2483 95.351 89.0231 96.3654C88.8355 97.3799 89.0231 98.1313 89.6236 98.7324C90.2991 99.4087 91.0872 99.6341 91.9879 99.4463C92.8886 99.2584 93.7143 98.77 94.4649 98.0186C95.4782 96.8914 95.9661 95.6516 95.9661 94.2614Z"
            fill="#68035E"
          />
          <path
            d="M111.578 84.7559C111.391 84.7183 111.203 84.6056 111.091 84.4178L98.0678 66.0078C97.9177 65.8199 97.8426 65.5945 97.8802 65.4067C97.9177 65.2188 98.0303 65.0309 98.2179 64.9182L105.912 59.4704C107.901 58.0427 109.74 57.4791 111.428 57.7045C113.117 57.93 114.506 58.8317 115.594 60.3721C116.345 61.4241 116.72 62.4761 116.72 63.5281C116.72 64.5801 116.532 65.4818 116.082 66.2708C117.17 66.0829 118.259 66.2332 119.422 66.6465C120.548 67.0974 121.486 67.8488 122.274 68.9008C123.025 69.9528 123.475 71.0799 123.588 72.2822C123.738 73.4845 123.513 74.6868 122.912 75.8515C122.35 77.0162 121.411 78.0682 120.098 79.0075L112.179 84.6432C111.954 84.7183 111.766 84.7935 111.578 84.7559ZM111.353 68.0742C112.592 67.2101 113.305 66.2708 113.568 65.2564C113.83 64.2419 113.568 63.2651 112.892 62.2507C112.216 61.2738 111.353 60.7478 110.378 60.7102C109.402 60.6351 108.276 61.0484 107.037 61.9501L101.783 65.6696L106.137 71.8314L111.353 68.0742ZM118.109 77.0162C119.31 76.1521 120.023 75.1752 120.248 74.0481C120.473 72.9209 120.21 71.8314 119.497 70.8169C118.747 69.7649 117.846 69.2014 116.683 69.0511C115.557 68.9008 114.356 69.2389 113.155 70.1031L107.638 74.0105L112.554 80.9612L118.109 77.0162Z"
            fill="#68035E"
          />
          <path
            d="M119.009 54.0601C118.822 54.0225 118.634 53.8722 118.522 53.6844L117.621 52.144C117.508 51.9561 117.471 51.7307 117.508 51.5052C117.546 51.2798 117.696 51.1295 117.884 51.0168L119.647 50.0024C119.835 49.8897 120.06 49.8521 120.285 49.9272C120.511 50.0024 120.661 50.1151 120.773 50.303L121.674 51.8434C121.787 52.0312 121.824 52.2567 121.749 52.4445C121.674 52.67 121.561 52.8202 121.336 52.933L119.572 53.9474C119.422 54.0977 119.235 54.1352 119.009 54.0601ZM129.893 72.4701C129.705 72.4325 129.518 72.2822 129.405 72.0943L121.261 57.8924C121.149 57.7045 121.111 57.4791 121.186 57.2912C121.224 57.1034 121.374 56.9155 121.561 56.8028L122.875 56.0514C123.063 55.9387 123.288 55.9011 123.475 55.9762C123.663 56.0138 123.851 56.1641 123.963 56.3519L132.107 70.5539C132.22 70.7418 132.257 70.9672 132.182 71.1551C132.145 71.3429 131.995 71.5308 131.807 71.6435L130.493 72.3949C130.306 72.4701 130.118 72.5076 129.893 72.4701Z"
            fill="#68035E"
          />
          <path
            d="M138.337 67.5482C137.136 67.5482 136.123 67.3604 135.372 66.9847C134.622 66.6089 134.134 66.2332 133.946 65.8199C133.833 65.5945 133.833 65.4067 133.946 65.2188C134.021 65.031 134.171 64.9182 134.321 64.8431L135.635 64.2044C135.747 64.1292 135.86 64.1292 135.973 64.1292C136.048 64.1292 136.198 64.1668 136.385 64.2795C137.136 64.6177 137.887 64.8431 138.637 64.8807C139.388 64.9182 140.251 64.7304 141.264 64.242C142.39 63.716 143.178 63.0772 143.666 62.3258C144.154 61.5744 144.192 60.823 143.816 60.034C143.554 59.508 143.216 59.1698 142.803 58.982C142.353 58.7941 141.752 58.7565 140.964 58.8317C140.176 58.9068 139.05 59.0947 137.586 59.4328C135.597 59.8837 134.021 59.9212 132.933 59.5455C131.844 59.1698 130.981 58.3432 130.418 57.1785C130.043 56.4271 129.93 55.563 130.043 54.6237C130.156 53.6844 130.568 52.7827 131.244 51.9185C131.919 51.0544 132.895 50.303 134.171 49.7018C135.485 49.0631 136.686 48.7626 137.849 48.7626C138.975 48.7626 139.913 48.9504 140.664 49.2885C141.414 49.6643 141.865 50.04 142.052 50.4157C142.128 50.6035 142.165 50.7914 142.09 50.9793C142.015 51.1671 141.865 51.3174 141.677 51.3925L140.476 51.9561C140.176 52.1064 139.913 52.0688 139.688 51.9185C139.163 51.6931 138.712 51.5428 138.375 51.4301C138.037 51.3174 137.586 51.3174 137.061 51.355C136.536 51.4301 135.935 51.618 135.222 51.9185C134.209 52.407 133.533 52.9705 133.195 53.6844C132.858 54.3607 132.858 55.0745 133.195 55.7884C133.421 56.2393 133.683 56.5398 134.059 56.7277C134.434 56.9155 134.997 56.9907 135.785 56.9531C136.536 56.9155 137.624 56.7277 139.05 56.3895C141.227 55.8635 142.916 55.7884 144.079 56.1641C145.28 56.5398 146.143 57.3288 146.706 58.5311C147.119 59.4328 147.269 60.3721 147.081 61.3114C146.931 62.2882 146.443 63.2275 145.618 64.0917C144.792 64.9934 143.704 65.7824 142.315 66.4211C140.852 67.2101 139.538 67.5482 138.337 67.5482Z"
            fill="#68035E"
          />
          <path
            d="M152.711 59.6958C150.947 58.8317 149.634 57.2161 148.77 54.8491L148.283 53.5341L147.87 52.2191C147.119 49.8145 147.157 47.7481 148.02 45.9823C148.883 44.2164 150.459 42.939 152.786 42.1124C154.362 41.5864 155.788 41.3986 157.027 41.5864C158.303 41.7743 159.354 42.1876 160.142 42.8263C160.968 43.465 161.53 44.2164 161.868 45.0054C161.943 45.1933 161.943 45.3811 161.831 45.6065C161.718 45.832 161.568 45.9447 161.38 46.0198L159.954 46.5083C159.729 46.5834 159.541 46.5834 159.429 46.5458C159.279 46.4707 159.129 46.358 158.903 46.1325C158.153 45.1933 157.365 44.6297 156.501 44.4043C155.676 44.1788 154.738 44.254 153.687 44.6297C152.298 45.1181 151.36 45.9071 150.872 47.0343C150.347 48.1614 150.384 49.5891 150.91 51.355L151.285 52.5197L151.698 53.6468C152.373 55.3751 153.236 56.5022 154.325 57.1034C155.413 57.667 156.652 57.7421 158.04 57.2537C159.091 56.878 159.917 56.352 160.405 55.6757C160.93 54.9994 161.193 54.0601 161.23 52.8578C161.23 52.5572 161.305 52.3318 161.38 52.2191C161.455 52.0688 161.606 51.9937 161.831 51.9185L163.257 51.4301C163.444 51.355 163.67 51.3925 163.857 51.4677C164.082 51.5804 164.195 51.7307 164.233 51.9185C164.458 52.7451 164.458 53.6468 164.195 54.6612C163.932 55.6757 163.369 56.6525 162.506 57.5918C161.606 58.5311 160.405 59.2825 158.828 59.8085C156.539 60.5975 154.475 60.5599 152.711 59.6958Z"
            fill="#68035E"
          />
          <path
            d="M171.1 55.3751C170.087 55.1121 169.186 54.6612 168.473 54.0225C167.76 53.3838 167.272 52.5573 167.085 51.618C166.747 50.1151 167.122 48.8001 168.098 47.5603C169.111 46.358 170.613 45.3435 172.639 44.5921L177.556 42.7887L177.33 41.8118C176.843 39.4824 175.229 38.6183 172.564 39.2194C171.513 39.4448 170.725 39.8206 170.2 40.3841C169.674 40.9477 169.299 41.5113 169.149 42.15C169.111 42.413 169.036 42.5633 168.961 42.676C168.886 42.7887 168.736 42.8638 168.548 42.9014L167.235 43.202C167.047 43.2396 166.86 43.202 166.672 43.0893C166.484 42.9766 166.372 42.8263 166.334 42.6008C166.222 42.0748 166.334 41.4361 166.672 40.6471C167.01 39.8581 167.61 39.1067 168.511 38.3553C169.412 37.6414 170.613 37.1154 172.039 36.7773C174.553 36.2137 176.505 36.4016 177.818 37.3409C179.169 38.2801 180.033 39.6327 180.408 41.361L182.81 52.3318C182.847 52.5573 182.81 52.7451 182.697 52.933C182.585 53.1208 182.397 53.2335 182.172 53.2711L180.671 53.6092C180.445 53.6468 180.258 53.6092 180.07 53.4965C179.92 53.3838 179.807 53.196 179.732 52.9705L179.395 51.4677C179.057 52.3318 178.494 53.0833 177.631 53.7971C176.805 54.511 175.642 54.9994 174.14 55.3375C173.202 55.6381 172.114 55.6005 171.1 55.3751ZM169.562 34.4479C169.524 34.26 169.562 34.1097 169.637 33.9594L171.476 30.3902C171.663 30.0896 171.814 29.9017 171.926 29.789C172.076 29.6763 172.301 29.6012 172.564 29.526L174.741 29.0376C175.154 28.9624 175.416 29.1127 175.529 29.526C175.567 29.6763 175.529 29.8266 175.454 29.9769L172.264 34.0346C172.114 34.2224 171.964 34.3727 171.814 34.4479C171.663 34.523 171.476 34.5981 171.213 34.6357L170.162 34.8611C169.862 34.9363 169.637 34.786 169.562 34.4479ZM177.743 50.5284C178.494 49.3637 178.644 47.8608 178.231 45.9823L178.043 45.043L174.215 46.4707C172.639 47.0343 171.513 47.7105 170.8 48.4244C170.087 49.1383 169.824 49.9273 169.975 50.7163C170.162 51.6555 170.725 52.2567 171.551 52.5948C172.414 52.933 173.352 52.9705 174.403 52.7451C175.904 52.4445 177.03 51.6931 177.743 50.5284Z"
            fill="#68035E"
          />
          <path
            d="M187.614 52.2943C187.463 52.1815 187.351 51.9937 187.313 51.7683L185.399 35.5374C185.362 35.312 185.437 35.1241 185.549 34.9363C185.662 34.7484 185.85 34.6733 186.075 34.6357L187.501 34.4479C187.764 34.4103 187.951 34.4854 188.139 34.5981C188.289 34.7484 188.402 34.9363 188.439 35.1617L188.627 36.6646C189.377 35.0114 190.879 34.0346 193.13 33.7716L194.481 33.6213C194.744 33.5837 194.932 33.6213 195.082 33.7716C195.232 33.8843 195.345 34.0721 195.345 34.3351L195.495 35.6126C195.532 35.838 195.457 36.0259 195.345 36.2137C195.232 36.364 195.044 36.4767 194.782 36.5143L192.83 36.7397C191.554 36.89 190.616 37.3784 189.978 38.205C189.34 39.0316 189.115 40.046 189.265 41.3234L190.466 51.4677C190.503 51.6931 190.428 51.881 190.278 52.0688C190.128 52.2191 189.94 52.3318 189.715 52.3694L188.176 52.5573C187.989 52.4821 187.801 52.407 187.614 52.2943Z"
            fill="#68035E"
          />
          <path
            d="M200.824 49.3637C199.435 47.9735 198.647 46.095 198.535 43.6904L198.46 42.1875V40.6847C198.497 38.3177 199.21 36.4016 200.561 34.9363C201.912 33.471 203.864 32.7196 206.341 32.682C208.818 32.6444 210.769 33.3207 212.196 34.7108C213.622 36.101 214.41 37.9796 214.522 40.3841C214.56 40.8725 214.597 41.3985 214.635 41.887C214.635 42.3754 214.635 42.8638 214.597 43.3898C214.56 45.7568 213.847 47.673 212.533 49.1382C211.182 50.6035 209.268 51.355 206.716 51.3925C204.202 51.4301 202.213 50.7538 200.824 49.3637ZM210.169 47.2973C210.995 46.358 211.445 45.0054 211.482 43.2395C211.482 43.0141 211.52 42.5633 211.482 41.9245C211.482 41.2858 211.445 40.835 211.407 40.6095C211.295 38.8437 210.807 37.5287 209.944 36.627C209.081 35.7253 207.88 35.2744 206.416 35.312C204.952 35.3496 203.789 35.838 202.926 36.7773C202.1 37.7166 201.65 39.0691 201.612 40.835V42.15L201.687 43.465C201.8 45.2308 202.288 46.5458 203.151 47.4475C204.052 48.3492 205.215 48.8001 206.679 48.7625C208.142 48.7625 209.306 48.2741 210.169 47.2973Z"
            fill="#68035E"
          />
          <path
            d="M229.196 44.0286C228.558 43.2396 228.333 42.3378 228.446 41.3234C228.558 40.309 229.046 39.52 229.872 38.8813C230.698 38.2426 231.598 38.0171 232.612 38.1299C233.625 38.2426 234.413 38.731 235.051 39.5576C235.652 40.3841 235.914 41.2858 235.764 42.3003C235.652 43.2771 235.164 44.1037 234.376 44.7048C233.587 45.3436 232.687 45.569 231.673 45.4563C230.623 45.2684 229.834 44.8176 229.196 44.0286Z"
            fill="#68035E"
          />
          <path
            d="M247.924 55.1121C247.849 54.9243 247.811 54.7364 247.886 54.511L254.304 32.9074C254.379 32.682 254.491 32.4942 254.679 32.3814C254.867 32.2687 255.054 32.2687 255.28 32.3063L263.649 34.786C266.126 35.5374 267.927 36.7021 268.978 38.3553C270.029 39.9709 270.216 41.9621 269.541 44.254C268.865 46.5458 267.627 48.0863 265.863 48.8377C264.099 49.5891 261.96 49.6267 259.445 48.8753L253.478 47.1094L251.001 55.4503C250.926 55.6757 250.813 55.826 250.626 55.9387C250.438 56.0514 250.251 56.0514 250.025 55.9763L248.374 55.4878C248.149 55.4503 247.999 55.3 247.924 55.1121ZM260.083 46.1701C263.423 47.147 265.525 46.2077 266.388 43.3147C266.801 41.887 266.726 40.6847 266.126 39.7079C265.525 38.6934 264.399 37.9796 262.71 37.4536L256.931 35.7253L254.304 44.4418L260.083 46.1701Z"
            fill="#68035E"
          />
          <path
            d="M267.964 61.6495C267.889 61.4617 267.889 61.2362 267.964 61.0484L274.269 45.9823C274.345 45.7568 274.495 45.6066 274.72 45.5314C274.907 45.4563 275.133 45.4563 275.32 45.5314L276.671 46.095C276.897 46.2077 277.047 46.358 277.159 46.5458C277.234 46.7337 277.234 46.9591 277.122 47.1846L276.521 48.5747C277.985 47.4851 279.786 47.41 281.85 48.2741L283.089 48.8001C283.314 48.9128 283.464 49.0256 283.539 49.2134C283.614 49.4013 283.614 49.5891 283.502 49.8521L283.014 51.0168C282.939 51.2423 282.789 51.3925 282.601 51.4677C282.413 51.5428 282.188 51.5428 281.963 51.4301L280.162 50.6787C278.998 50.1903 277.947 50.1527 276.972 50.5284C276.033 50.9417 275.283 51.6931 274.795 52.8954L270.854 62.2882C270.779 62.5137 270.629 62.6264 270.404 62.7391C270.179 62.8142 269.991 62.8142 269.766 62.7015L268.34 62.1004C268.19 61.9877 268.04 61.8374 267.964 61.6495Z"
            fill="#68035E"
          />
          <path
            d="M280.912 65.5945C280.387 63.6784 280.65 61.6495 281.7 59.5455L282.376 58.2305L283.126 56.9531C284.327 54.9242 285.904 53.5717 287.78 53.0081C289.694 52.407 291.721 52.7075 293.935 53.8722C296.112 55.037 297.5 56.6149 298.026 58.5311C298.589 60.4472 298.326 62.4761 297.275 64.6177C297.088 65.0685 296.862 65.5194 296.637 65.9702C296.412 66.4211 296.149 66.8344 295.887 67.2476C294.686 69.2765 293.147 70.6291 291.233 71.2302C289.356 71.8314 287.292 71.5308 285.078 70.3285C282.826 69.0511 281.438 67.5106 280.912 65.5945ZM290.032 68.4124C291.233 67.9991 292.284 67.0598 293.184 65.5194C293.297 65.3315 293.522 64.9558 293.822 64.3922C294.123 63.8286 294.31 63.4154 294.423 63.2275C295.211 61.6495 295.436 60.2594 295.098 59.0195C294.798 57.7797 293.973 56.8028 292.697 56.1265C291.383 55.4127 290.145 55.2624 288.944 55.6757C287.743 56.089 286.692 57.0282 285.791 58.5687L285.153 59.6958L284.553 60.8605C283.764 62.4385 283.539 63.8287 283.877 65.0685C284.177 66.3084 285.003 67.2852 286.279 67.9615C287.555 68.6754 288.831 68.8256 290.032 68.4124Z"
            fill="#68035E"
          />
          <path
            d="M297.5 74.7995C297.425 72.8082 298.026 70.8169 299.339 68.8256L299.902 67.9615L300.54 67.1349C302.004 65.2564 303.655 64.0165 305.569 63.4529C307.483 62.8894 309.397 63.2651 311.349 64.6552C313.3 66.0454 314.314 67.8112 314.426 69.9904L319.042 63.4905C319.193 63.3027 319.343 63.1899 319.568 63.1524C319.793 63.1148 319.981 63.1524 320.168 63.3027L321.444 64.2044C321.632 64.3547 321.745 64.5049 321.782 64.7304C321.82 64.9558 321.782 65.1437 321.632 65.3315L308.346 84.0045C308.196 84.1923 308.046 84.305 307.821 84.3426C307.596 84.3802 307.408 84.3426 307.221 84.1923L306.02 83.3282C305.832 83.1779 305.719 83.0276 305.682 82.8398C305.644 82.6519 305.719 82.4265 305.832 82.2386L306.695 81.0363C304.631 81.7126 302.567 81.2993 300.54 79.8716C298.626 78.4815 297.575 76.7908 297.5 74.7995ZM306.545 78.8572C307.821 78.4439 308.909 77.6549 309.81 76.4902C309.96 76.3023 310.223 75.9642 310.598 75.4382C310.974 74.9122 311.236 74.5365 311.349 74.3111C312.137 73.1464 312.475 71.9065 312.362 70.5539C312.287 69.2389 311.574 68.1118 310.298 67.2101C308.947 66.2332 307.671 65.9702 306.432 66.3835C305.232 66.7968 304.106 67.6609 303.055 68.9759L302.454 69.7649C299.902 73.3718 299.94 76.1145 302.604 78.0306C303.993 78.9699 305.269 79.2329 306.545 78.8572Z"
            fill="#68035E"
          />
          <path
            d="M313.788 87.2732C313.863 85.4322 314.689 83.6288 316.265 81.9005L322.833 74.7243C322.983 74.5365 323.171 74.4613 323.396 74.4613C323.621 74.4613 323.809 74.5365 323.959 74.6868L325.122 75.7763C325.31 75.9266 325.385 76.1145 325.385 76.3399C325.385 76.5653 325.31 76.7532 325.16 76.9035L318.705 83.9669C316.34 86.5593 316.265 88.8512 318.517 90.9176C319.605 91.932 320.769 92.3453 322.045 92.2702C323.321 92.1575 324.484 91.5187 325.61 90.3165L332.065 83.253C332.215 83.0652 332.403 82.99 332.628 82.99C332.853 82.99 333.041 83.0652 333.191 83.2155L334.355 84.305C334.542 84.4553 334.617 84.6432 334.617 84.8686C334.617 85.094 334.542 85.2819 334.392 85.4322L323.321 97.4926C323.171 97.6804 322.983 97.7556 322.758 97.7556C322.533 97.7556 322.345 97.6804 322.195 97.5302L321.107 96.5157C320.919 96.3654 320.844 96.1776 320.844 95.9522C320.844 95.7267 320.919 95.5389 321.069 95.3886L322.12 94.2614C321.032 94.4869 319.981 94.4493 319.042 94.1487C318.067 93.8857 317.091 93.2846 316.078 92.3453C314.501 90.7673 313.713 89.1142 313.788 87.2732Z"
            fill="#68035E"
          />
          <path
            d="M328.162 101.212C328.312 99.2584 329.325 97.4174 331.202 95.7643L332.215 94.825L333.304 93.9609C335.255 92.3829 337.207 91.669 339.158 91.8193C341.11 91.9696 342.911 92.984 344.487 94.8626C345.576 96.14 346.251 97.3799 346.552 98.6197C346.852 99.8596 346.852 100.987 346.552 102.001C346.251 103.016 345.801 103.805 345.163 104.406C345.013 104.556 344.825 104.631 344.6 104.594C344.375 104.556 344.187 104.481 344.037 104.331L343.061 103.166C342.911 102.978 342.836 102.828 342.836 102.677C342.836 102.527 342.911 102.302 343.061 102.039C343.662 100.987 343.887 100.047 343.774 99.1833C343.662 98.3192 343.249 97.4926 342.536 96.6285C341.598 95.5389 340.509 94.9377 339.271 94.8626C338.032 94.7875 336.681 95.351 335.255 96.4782L334.317 97.2296L333.416 98.0562C332.065 99.296 331.315 100.536 331.202 101.776C331.089 103.016 331.465 104.18 332.44 105.27C333.191 106.134 333.942 106.66 334.767 106.923C335.593 107.148 336.569 107.073 337.695 106.66C337.995 106.585 338.182 106.547 338.37 106.547C338.52 106.585 338.67 106.66 338.82 106.848L339.796 108.013C339.946 108.163 339.984 108.351 339.984 108.614C339.984 108.839 339.871 109.027 339.721 109.14C339.046 109.666 338.182 110.004 337.169 110.117C336.156 110.267 335.03 110.079 333.829 109.628C332.628 109.14 331.502 108.276 330.414 107.036C328.725 105.082 328.012 103.166 328.162 101.212Z"
            fill="#68035E"
          />
          <path
            d="M343.474 113.31L351.055 108.013L349.479 105.758C349.329 105.57 349.291 105.383 349.329 105.195C349.366 105.007 349.479 104.857 349.667 104.706L350.567 104.068C350.755 103.917 350.943 103.88 351.168 103.917C351.355 103.955 351.543 104.068 351.656 104.255L353.232 106.51L357.961 103.203C358.148 103.053 358.336 103.016 358.561 103.053C358.749 103.091 358.936 103.203 359.049 103.391L359.912 104.631C360.062 104.819 360.1 105.007 360.062 105.232C360.025 105.458 359.912 105.608 359.725 105.758L354.996 109.065L357.435 112.596C357.585 112.784 357.623 112.972 357.585 113.197C357.548 113.423 357.435 113.573 357.248 113.723L356.347 114.362C356.159 114.512 355.972 114.55 355.784 114.512C355.596 114.475 355.409 114.362 355.296 114.174L352.857 110.643L345.463 115.827C344.487 116.504 343.887 117.18 343.624 117.819C343.362 118.457 343.474 119.171 344 119.923L345.201 121.651C345.351 121.839 345.388 122.027 345.351 122.215C345.313 122.402 345.201 122.59 345.013 122.703L344.075 123.342C343.887 123.492 343.699 123.53 343.474 123.492C343.286 123.454 343.099 123.342 342.986 123.154L341.635 121.238C339.534 118.232 340.172 115.602 343.474 113.31Z"
            fill="#68035E"
          />
          <path
            d="M349.592 133.524C349.667 133.336 349.817 133.185 350.004 133.073L370.345 123.342C370.571 123.229 370.796 123.191 370.983 123.267C371.171 123.342 371.321 123.454 371.434 123.68L374.811 130.743C376.313 133.899 376.688 136.492 375.937 138.558C375.187 140.625 373.423 142.353 370.608 143.743C370.008 144.044 369.22 144.457 368.206 144.945C367.193 145.434 366.367 145.809 365.729 146.072C363.74 146.974 361.976 147.425 360.475 147.425C358.974 147.425 357.585 146.937 356.384 145.96C355.183 144.983 354.058 143.442 353.082 141.338L349.667 134.125C349.516 133.899 349.516 133.711 349.592 133.524ZM355.559 139.948C356.234 141.414 357.022 142.466 357.811 143.142C358.636 143.818 359.574 144.119 360.588 144.119C361.639 144.081 362.915 143.743 364.416 143.067C365.692 142.503 366.48 142.165 366.78 142.015C367.118 141.864 367.868 141.451 369.069 140.85C371.134 139.798 372.41 138.596 372.935 137.206C373.46 135.815 373.198 134.012 372.147 131.833L369.933 127.174L353.269 135.139L355.559 139.948Z"
            fill="#68035E"
          />
          <path
            d="M360.813 154.826C361.789 153.023 363.44 151.67 365.804 150.769L366.893 150.355L367.981 150.055C370.383 149.416 372.522 149.529 374.361 150.431C376.2 151.295 377.476 152.873 378.189 155.127C378.977 157.532 378.789 159.673 377.701 161.477C376.613 163.318 374.736 164.633 372.109 165.497L371.509 165.685C371.284 165.76 371.096 165.76 370.908 165.647C370.721 165.534 370.608 165.384 370.533 165.159L366.855 153.737L366.555 153.85C365.166 154.376 364.078 155.202 363.327 156.329C362.577 157.456 362.427 158.696 362.877 160.049C363.215 161.063 363.665 161.852 364.303 162.378C364.903 162.904 365.504 163.242 366.029 163.43C366.367 163.543 366.555 163.656 366.667 163.731C366.742 163.806 366.855 163.994 366.93 164.257L367.38 165.722C367.456 165.91 367.456 166.098 367.38 166.286C367.305 166.474 367.193 166.586 367.005 166.624C366.517 166.774 365.842 166.661 364.979 166.286C364.115 165.91 363.252 165.271 362.389 164.332C361.563 163.393 360.888 162.228 360.437 160.838C359.762 158.621 359.875 156.63 360.813 154.826ZM372.034 162.19L372.147 162.153C373.685 161.664 374.811 160.838 375.487 159.673C376.162 158.546 376.313 157.269 375.862 155.841C375.412 154.413 374.549 153.436 373.348 152.948C372.109 152.459 370.758 152.459 369.219 152.948L369.107 152.985L372.034 162.19Z"
            fill="#68035E"
          />
          <path
            d="M364.904 173.838C365.204 172.673 365.617 171.734 366.18 171.095C366.742 170.456 367.23 170.08 367.643 169.968C367.868 169.93 368.056 169.968 368.206 170.08C368.356 170.231 368.469 170.381 368.506 170.569L368.807 171.997C368.844 172.147 368.844 172.26 368.807 172.335C368.769 172.41 368.694 172.56 368.581 172.71C368.056 173.349 367.681 174.025 367.456 174.739C367.23 175.453 367.23 176.355 367.456 177.444C367.718 178.647 368.131 179.586 368.732 180.225C369.332 180.863 370.045 181.089 370.908 180.939C371.471 180.826 371.884 180.563 372.184 180.187C372.447 179.811 372.672 179.21 372.785 178.421C372.897 177.632 372.972 176.468 373.01 174.965C373.047 172.898 373.385 171.395 374.023 170.419C374.661 169.442 375.637 168.803 376.913 168.578C377.739 168.39 378.602 168.502 379.465 168.841C380.328 169.179 381.116 169.78 381.792 170.682C382.467 171.583 382.955 172.673 383.218 174.025C383.518 175.453 383.518 176.693 383.256 177.82C382.993 178.91 382.58 179.774 382.055 180.413C381.529 181.051 381.041 181.427 380.628 181.502C380.441 181.54 380.253 181.502 380.066 181.389C379.915 181.277 379.803 181.126 379.765 180.901L379.503 179.624C379.428 179.323 379.503 179.06 379.728 178.872C380.066 178.384 380.328 178.008 380.516 177.707C380.704 177.407 380.816 176.994 380.891 176.468C380.966 175.942 380.929 175.303 380.779 174.551C380.553 173.462 380.141 172.635 379.578 172.147C379.015 171.658 378.339 171.471 377.551 171.658C377.063 171.771 376.688 171.959 376.425 172.26C376.162 172.56 375.937 173.124 375.787 173.875C375.637 174.627 375.562 175.716 375.562 177.181C375.562 179.436 375.224 181.051 374.549 182.141C373.911 183.193 372.897 183.869 371.621 184.132C370.646 184.32 369.707 184.245 368.807 183.832C367.906 183.456 367.118 182.742 366.442 181.728C365.767 180.713 365.279 179.473 364.979 177.97C364.641 176.392 364.603 175.002 364.904 173.838Z"
            fill="#68035E"
          />
          <path
            d="M367.343 188.866C367.456 188.716 367.643 188.603 367.869 188.566L384.119 186.574C384.344 186.537 384.532 186.612 384.719 186.725C384.869 186.837 384.982 187.025 385.02 187.251L385.207 188.753C385.245 188.979 385.17 189.167 385.057 189.355C384.944 189.505 384.757 189.618 384.532 189.655L368.281 191.646C368.056 191.684 367.869 191.609 367.681 191.496C367.531 191.383 367.418 191.196 367.381 190.97L367.193 189.467C367.155 189.204 367.231 189.016 367.343 188.866ZM388.472 185.973C388.585 185.823 388.772 185.71 388.998 185.673L390.762 185.447C390.987 185.41 391.174 185.485 391.362 185.597C391.55 185.71 391.625 185.898 391.662 186.123L391.925 188.152C391.963 188.378 391.887 188.603 391.737 188.753C391.587 188.941 391.4 189.054 391.174 189.054L389.41 189.279C389.185 189.317 388.998 189.242 388.81 189.092C388.66 188.941 388.547 188.753 388.51 188.528L388.247 186.499C388.322 186.349 388.36 186.161 388.472 185.973Z"
            fill="#68035E"
          />
          <path
            d="M361.564 199.311C362.164 198.146 362.877 197.282 363.703 196.794C364.528 196.305 365.204 196.042 365.804 196.005C366.03 196.005 366.18 196.08 366.33 196.23C366.48 196.38 366.555 196.568 366.555 196.794L366.593 198.297C366.593 198.522 366.555 198.672 366.442 198.823C366.33 198.973 366.142 199.086 365.879 199.198C364.078 199.912 363.177 201.415 363.252 203.669C363.327 205.398 363.74 206.675 364.491 207.464C365.241 208.253 366.555 208.629 368.431 208.591L370.796 208.516C369.032 207.239 368.131 205.398 368.056 202.993C367.981 200.551 368.694 198.71 370.233 197.432C371.772 196.155 373.723 195.479 376.088 195.328L377.063 195.253H378.002C380.366 195.253 382.355 195.817 383.969 196.982C385.583 198.146 386.446 199.95 386.521 202.392C386.596 204.796 385.77 206.713 384.044 208.14L385.545 208.103C385.77 208.103 385.958 208.178 386.145 208.291C386.296 208.441 386.408 208.629 386.408 208.854L386.446 210.357C386.446 210.582 386.371 210.77 386.221 210.92C386.07 211.071 385.883 211.146 385.658 211.183L368.919 211.747C363.59 211.935 360.85 209.343 360.663 203.97C360.7 202.016 360.963 200.476 361.564 199.311ZM372.56 207.126C373.648 207.915 374.924 208.29 376.388 208.328C376.613 208.328 376.988 208.328 377.589 208.328C378.152 208.29 378.564 208.291 378.752 208.253C380.216 208.14 381.454 207.652 382.468 206.788C383.481 205.924 383.969 204.684 383.931 203.106C383.856 201.453 383.293 200.25 382.242 199.536C381.154 198.823 379.803 198.447 378.152 198.447H377.251L376.313 198.522C374.661 198.635 373.31 199.086 372.297 199.875C371.284 200.664 370.796 201.866 370.833 203.557C370.908 205.135 371.471 206.337 372.56 207.126Z"
            fill="#68035E"
          />
          <path
            d="M368.732 216.481C368.882 216.331 369.107 216.293 369.332 216.293L385.62 217.758C385.845 217.796 386.033 217.871 386.183 218.021C386.333 218.172 386.371 218.397 386.371 218.623L386.22 220.088C386.183 220.351 386.108 220.539 385.958 220.651C385.808 220.764 385.62 220.839 385.357 220.802L383.856 220.651C384.644 221.403 385.207 222.267 385.582 223.206C385.958 224.146 386.07 225.31 385.958 226.663C385.77 228.88 384.907 230.495 383.368 231.585C381.867 232.674 379.915 233.125 377.626 232.9L367.906 232.036C367.681 231.998 367.493 231.923 367.343 231.773C367.193 231.622 367.155 231.397 367.155 231.171L367.306 229.593C367.343 229.368 367.418 229.18 367.568 229.03C367.718 228.88 367.944 228.842 368.169 228.842L377.701 229.706C379.353 229.856 380.666 229.556 381.679 228.842C382.655 228.128 383.218 227.001 383.368 225.536C383.518 224.07 383.143 222.868 382.28 221.891C381.417 220.952 380.178 220.388 378.564 220.238L369.032 219.374C368.807 219.336 368.619 219.261 368.469 219.111C368.319 218.961 368.281 218.735 368.281 218.51L368.431 216.932C368.469 216.782 368.544 216.631 368.732 216.481Z"
            fill="#68035E"
          />
          <path
            d="M367.868 238.122C369.595 237.07 371.696 236.732 374.211 237.108L375.374 237.333L376.463 237.596C378.865 238.272 380.628 239.437 381.792 241.128C382.918 242.819 383.256 244.81 382.768 247.102C382.242 249.581 381.041 251.347 379.165 252.362C377.288 253.376 374.999 253.602 372.297 253.038L371.696 252.925C371.471 252.888 371.284 252.775 371.171 252.587C371.058 252.399 371.021 252.211 371.058 251.986L373.573 240.264L373.273 240.189C371.809 239.926 370.458 240.113 369.219 240.715C368.019 241.316 367.268 242.33 366.968 243.683C366.743 244.735 366.78 245.636 367.043 246.388C367.305 247.139 367.643 247.74 368.019 248.154C368.244 248.417 368.356 248.605 368.394 248.717C368.431 248.83 368.431 249.055 368.356 249.318L368.019 250.821C367.981 251.009 367.868 251.197 367.718 251.31C367.568 251.422 367.418 251.46 367.23 251.422C366.743 251.31 366.217 250.896 365.654 250.107C365.091 249.356 364.678 248.342 364.416 247.139C364.153 245.899 364.153 244.584 364.491 243.157C365.054 240.865 366.142 239.212 367.868 238.122ZM373.911 250.145L374.023 250.183C375.6 250.521 376.988 250.37 378.152 249.732C379.315 249.093 380.066 248.041 380.366 246.613C380.666 245.148 380.441 243.908 379.615 242.856C378.79 241.804 377.626 241.128 376.05 240.79L375.937 240.752L373.911 250.145Z"
            fill="#68035E"
          />
          <path
            d="M362.201 254.804C362.389 254.691 362.577 254.691 362.802 254.766L378.339 259.763C378.564 259.838 378.714 259.951 378.827 260.139C378.94 260.327 378.94 260.552 378.865 260.74L378.414 262.13C378.339 262.356 378.189 262.544 378.001 262.619C377.814 262.731 377.589 262.731 377.363 262.656L375.9 262.205C377.101 263.596 377.363 265.361 376.65 267.503L376.238 268.78C376.162 269.006 376.05 269.194 375.862 269.269C375.675 269.344 375.487 269.381 375.224 269.269L374.023 268.893C373.798 268.818 373.648 268.705 373.535 268.517C373.423 268.329 373.423 268.142 373.498 267.879L374.098 266C374.474 264.798 374.436 263.746 373.948 262.807C373.46 261.905 372.635 261.229 371.434 260.853L361.751 257.734C361.526 257.659 361.376 257.547 361.301 257.321C361.226 257.133 361.188 256.908 361.263 256.682L361.751 255.217C361.901 255.067 362.014 254.917 362.201 254.804Z"
            fill="#68035E"
          />
          <path
            d="M354.883 275.017C355.709 274.379 356.61 273.965 357.585 273.815C358.561 273.665 359.462 273.777 360.325 274.228C361.676 274.905 362.539 275.994 362.839 277.535C363.177 279.075 362.99 280.878 362.352 282.945L360.738 287.904L361.639 288.355C363.778 289.407 365.429 288.693 366.63 286.214C367.08 285.274 367.268 284.41 367.193 283.621C367.08 282.87 366.855 282.193 366.48 281.705C366.33 281.517 366.217 281.367 366.18 281.254C366.142 281.141 366.18 280.991 366.255 280.803L366.855 279.601C366.93 279.413 367.08 279.3 367.305 279.225C367.493 279.15 367.681 279.15 367.868 279.263C368.356 279.488 368.769 279.977 369.182 280.728C369.595 281.48 369.82 282.456 369.82 283.584C369.82 284.748 369.52 285.988 368.844 287.341C367.718 289.67 366.367 291.06 364.791 291.511C363.215 292 361.639 291.812 360.025 291.023L349.967 286.063C349.742 285.951 349.629 285.8 349.554 285.612C349.479 285.425 349.516 285.199 349.592 285.011L350.267 283.659C350.38 283.433 350.53 283.321 350.717 283.245C350.905 283.17 351.13 283.208 351.318 283.321L352.669 283.997C352.219 283.208 351.956 282.269 351.956 281.179C351.918 280.089 352.256 278.85 352.932 277.497C353.382 276.52 354.058 275.694 354.883 275.017ZM354.545 283.283C354.996 284.598 356.084 285.65 357.773 286.477L358.636 286.89L359.875 282.982C360.4 281.404 360.588 280.089 360.475 279.075C360.363 278.061 359.912 277.384 359.162 277.009C358.298 276.595 357.51 276.633 356.685 277.084C355.896 277.535 355.258 278.248 354.808 279.225C354.17 280.615 354.058 281.968 354.545 283.283Z"
            fill="#68035E"
          />
          <path
            d="M347.19 289.858C347.415 289.82 347.602 289.858 347.79 289.971L361.488 298.913C361.676 299.025 361.789 299.213 361.826 299.401C361.864 299.626 361.826 299.814 361.714 300.002L360.888 301.242C360.738 301.467 360.588 301.58 360.4 301.618C360.212 301.655 360.025 301.618 359.8 301.468L358.524 300.641C358.861 301.693 358.974 302.707 358.861 303.722C358.749 304.736 358.336 305.826 357.585 306.953C356.384 308.794 354.846 309.846 353.007 310.109C351.168 310.372 349.254 309.846 347.302 308.569L339.121 303.271C338.933 303.158 338.821 302.97 338.783 302.783C338.745 302.557 338.783 302.369 338.896 302.181L339.759 300.866C339.871 300.678 340.059 300.566 340.247 300.528C340.472 300.491 340.659 300.528 340.847 300.641L348.841 305.863C350.23 306.765 351.543 307.141 352.744 306.953C353.945 306.765 354.958 306.051 355.784 304.811C356.572 303.571 356.835 302.332 356.535 301.092C356.234 299.852 355.409 298.762 354.02 297.898L346.026 292.676C345.839 292.563 345.726 292.375 345.688 292.187C345.651 291.962 345.688 291.774 345.801 291.586L346.664 290.271C346.814 290.046 347.002 289.933 347.19 289.858Z"
            fill="#68035E"
          />
          <path
            d="M336.681 308.418C338.67 308.493 340.584 309.282 342.461 310.785L343.287 311.424L344.037 312.138C345.801 313.753 346.852 315.519 347.265 317.473C347.678 319.427 347.115 321.305 345.576 323.146C344.037 324.987 342.161 325.851 340.022 325.776L346.101 330.923C346.289 331.074 346.364 331.262 346.402 331.449C346.402 331.675 346.364 331.863 346.214 332.013L345.201 333.215C345.051 333.403 344.863 333.478 344.675 333.516C344.45 333.516 344.262 333.478 344.112 333.328L326.623 318.487C326.436 318.337 326.361 318.149 326.323 317.961C326.323 317.736 326.361 317.548 326.511 317.398L327.487 316.271C327.637 316.083 327.824 316.008 328.012 316.008C328.2 316.008 328.387 316.083 328.575 316.233L329.701 317.21C329.213 315.068 329.776 313.077 331.39 311.161C332.966 309.207 334.73 308.343 336.681 308.418ZM331.915 317.135C332.215 318.45 332.891 319.614 333.979 320.591C334.167 320.779 334.467 321.08 334.955 321.455C335.443 321.869 335.781 322.169 336.006 322.282C337.094 323.146 338.333 323.597 339.646 323.635C340.96 323.672 342.123 323.071 343.137 321.869C344.225 320.591 344.6 319.351 344.3 318.112C344 316.872 343.249 315.669 342.011 314.542L341.26 313.866C337.882 311.011 335.143 310.823 333.041 313.302C331.99 314.542 331.615 315.82 331.915 317.135Z"
            fill="#68035E"
          />
          <path
            d="M312.737 322.169C312.925 322.169 313.113 322.282 313.3 322.47L327.937 340.128C328.087 340.316 328.162 340.504 328.125 340.73C328.087 340.917 328.012 341.105 327.824 341.256L326.698 342.195C326.511 342.345 326.323 342.42 326.098 342.42C325.91 342.42 325.723 342.308 325.573 342.082L324.597 340.917C324.709 343.134 323.809 345.05 321.895 346.591C320.018 348.131 318.142 348.695 316.228 348.281C314.314 347.868 312.587 346.778 311.011 345.013C310.823 344.825 310.561 344.524 310.223 344.149C309.885 343.735 309.66 343.435 309.51 343.209C308.046 341.368 307.296 339.452 307.258 337.498C307.221 335.545 308.121 333.779 309.96 332.238C311.874 330.66 313.863 330.134 315.927 330.698L310.786 324.499C310.636 324.311 310.561 324.123 310.598 323.898C310.636 323.672 310.711 323.522 310.898 323.372L312.099 322.395C312.325 322.244 312.512 322.169 312.737 322.169ZM310.448 337.423C310.523 338.701 311.086 340.016 312.099 341.368C312.212 341.556 312.437 341.819 312.7 342.157C315.515 345.576 318.179 346.215 320.694 344.149C321.932 343.134 322.533 341.932 322.458 340.579C322.42 339.227 321.932 337.987 321.031 336.822C320.881 336.597 320.619 336.259 320.206 335.77C319.793 335.282 319.493 334.944 319.305 334.756C318.367 333.704 317.241 333.065 315.965 332.802C314.651 332.539 313.413 332.915 312.212 333.929C310.974 334.944 310.373 336.146 310.448 337.423Z"
            fill="#68035E"
          />
          <path
            d="M300.165 340.617C301.216 340.504 302.192 340.617 303.092 340.955C303.993 341.331 304.706 341.894 305.231 342.721C306.057 343.998 306.207 345.388 305.682 346.854C305.156 348.357 304.106 349.784 302.454 351.25L298.476 354.669L299.001 355.495C300.277 357.486 302.079 357.749 304.368 356.247C305.269 355.683 305.869 355.044 306.17 354.33C306.47 353.617 306.62 352.94 306.545 352.302C306.507 352.076 306.507 351.888 306.545 351.738C306.582 351.625 306.695 351.513 306.845 351.4L307.971 350.686C308.159 350.573 308.346 350.536 308.534 350.573C308.759 350.611 308.909 350.724 309.022 350.911C309.322 351.362 309.435 352.001 309.397 352.865C309.36 353.729 309.059 354.669 308.459 355.645C307.858 356.622 306.958 357.562 305.682 358.35C303.505 359.741 301.629 360.229 300.052 359.853C298.476 359.44 297.2 358.501 296.224 356.998L290.144 347.568C290.032 347.38 289.994 347.192 290.032 346.966C290.069 346.741 290.182 346.591 290.407 346.478L291.683 345.651C291.871 345.539 292.059 345.501 292.284 345.539C292.471 345.576 292.659 345.727 292.772 345.914L293.597 347.192C293.597 346.29 293.897 345.351 294.423 344.412C294.948 343.472 295.887 342.571 297.163 341.744C298.101 341.105 299.114 340.767 300.165 340.617ZM295.624 347.492C295.361 348.845 295.736 350.31 296.75 351.926L297.275 352.752L300.353 350.085C301.629 348.995 302.454 347.981 302.867 347.042C303.28 346.102 303.28 345.313 302.83 344.599C302.304 343.81 301.591 343.397 300.69 343.397C299.752 343.397 298.851 343.66 297.988 344.261C296.675 345.05 295.887 346.14 295.624 347.492Z"
            fill="#68035E"
          />
          <path
            d="M284.252 350.348C285.453 350.31 286.467 350.498 287.217 350.874C288.005 351.25 288.456 351.625 288.681 352.001C288.793 352.189 288.793 352.414 288.718 352.602C288.643 352.79 288.493 352.903 288.343 353.015L287.03 353.654C286.917 353.729 286.804 353.729 286.692 353.729C286.617 353.729 286.467 353.692 286.241 353.579C285.491 353.241 284.74 353.053 283.99 353.015C283.239 352.978 282.376 353.203 281.363 353.692C280.237 354.255 279.449 354.894 278.998 355.645C278.548 356.397 278.51 357.148 278.886 357.937C279.148 358.463 279.486 358.801 279.936 358.952C280.387 359.102 280.987 359.139 281.775 359.064C282.564 358.989 283.689 358.764 285.153 358.388C287.142 357.9 288.681 357.825 289.807 358.2C290.933 358.576 291.758 359.365 292.359 360.53C292.734 361.281 292.884 362.145 292.772 363.084C292.659 364.024 292.284 364.925 291.608 365.827C290.933 366.729 289.957 367.48 288.756 368.081C287.442 368.72 286.241 369.058 285.116 369.096C283.99 369.133 283.051 368.946 282.301 368.607C281.55 368.269 281.062 367.894 280.875 367.48C280.8 367.292 280.762 367.105 280.837 366.917C280.912 366.729 281.025 366.579 281.212 366.503L282.376 365.902C282.676 365.752 282.939 365.752 283.164 365.94C283.727 366.165 284.14 366.316 284.515 366.428C284.853 366.541 285.303 366.541 285.829 366.466C286.354 366.391 286.954 366.203 287.63 365.865C288.643 365.376 289.319 364.775 289.619 364.061C289.957 363.385 289.919 362.671 289.582 361.957C289.356 361.506 289.056 361.206 288.681 361.018C288.306 360.83 287.743 360.755 286.954 360.83C286.204 360.905 285.116 361.093 283.727 361.469C281.55 362.032 279.899 362.145 278.698 361.769C277.497 361.394 276.596 360.642 276.033 359.44C275.583 358.538 275.433 357.637 275.583 356.66C275.733 355.683 276.183 354.744 277.009 353.842C277.797 352.94 278.886 352.151 280.274 351.437C281.738 350.761 283.051 350.385 284.252 350.348Z"
            fill="#68035E"
          />
          <path
            d="M268.865 357.486C270.066 357.599 271.042 357.862 271.755 358.313C272.468 358.764 272.918 359.177 273.069 359.59C273.144 359.816 273.144 360.004 273.031 360.191C272.918 360.379 272.768 360.492 272.618 360.567L271.267 361.056C271.155 361.093 271.042 361.131 270.929 361.093C270.854 361.093 270.704 361.018 270.517 360.905C269.803 360.492 269.053 360.229 268.34 360.079C267.589 359.966 266.726 360.079 265.675 360.492C264.512 360.905 263.649 361.469 263.123 362.183C262.598 362.859 262.448 363.648 262.748 364.437C262.936 364.963 263.273 365.339 263.686 365.564C264.099 365.79 264.699 365.865 265.525 365.865C266.313 365.865 267.477 365.752 268.978 365.564C271.004 365.264 272.543 365.376 273.631 365.865C274.72 366.353 275.47 367.217 275.921 368.457C276.221 369.246 276.259 370.11 276.071 371.05C275.883 371.989 275.395 372.853 274.607 373.642C273.857 374.469 272.806 375.107 271.53 375.596C270.179 376.084 268.94 376.31 267.814 376.197C266.689 376.084 265.75 375.821 265.075 375.408C264.362 374.995 263.949 374.581 263.799 374.168C263.724 373.98 263.724 373.792 263.836 373.604C263.911 373.417 264.061 373.304 264.249 373.229L265.488 372.778C265.788 372.665 266.051 372.703 266.276 372.891C266.801 373.154 267.214 373.379 267.552 373.492C267.889 373.642 268.302 373.68 268.865 373.68C269.391 373.68 270.029 373.529 270.742 373.266C271.793 372.891 272.543 372.365 272.918 371.688C273.331 371.05 273.369 370.336 273.106 369.622C272.956 369.171 272.693 368.833 272.318 368.607C271.98 368.382 271.417 368.269 270.629 368.232C269.879 368.194 268.753 368.307 267.327 368.495C265.112 368.833 263.423 368.758 262.298 368.269C261.134 367.781 260.346 366.917 259.896 365.677C259.558 364.738 259.52 363.798 259.746 362.859C260.008 361.92 260.571 361.018 261.434 360.191C262.335 359.365 263.498 358.689 264.925 358.163C266.313 357.599 267.627 357.374 268.865 357.486Z"
            fill="#68035E"
          />
          <path
            d="M254.416 362.183C254.604 362.295 254.717 362.446 254.792 362.671L259.22 378.451C259.295 378.677 259.258 378.864 259.145 379.052C259.033 379.24 258.882 379.353 258.657 379.428L257.194 379.841C256.968 379.916 256.781 379.879 256.593 379.766C256.405 379.653 256.293 379.503 256.218 379.278L251.789 363.498C251.714 363.272 251.752 363.084 251.864 362.897C251.977 362.709 252.127 362.596 252.352 362.521L253.816 362.108C254.004 362.07 254.229 362.07 254.416 362.183ZM260.459 382.659C260.646 382.772 260.759 382.922 260.834 383.148L261.322 384.876C261.397 385.101 261.359 385.289 261.247 385.515C261.134 385.702 260.984 385.853 260.759 385.89L258.807 386.454C258.582 386.529 258.394 386.491 258.169 386.379C257.982 386.266 257.832 386.116 257.794 385.89L257.306 384.162C257.231 383.937 257.269 383.749 257.381 383.561C257.494 383.373 257.681 383.26 257.869 383.185L259.821 382.622C260.083 382.546 260.271 382.584 260.459 382.659Z"
            fill="#68035E"
          />
          <path
            d="M246.01 366.09C247.623 367.217 248.712 368.983 249.237 371.275L249.537 372.74L249.8 374.206C250.138 376.535 249.8 378.564 248.674 380.217C247.586 381.87 245.784 382.96 243.345 383.411C240.906 383.861 238.879 383.523 237.228 382.396C235.576 381.269 234.526 379.541 233.963 377.211C233.813 376.723 233.7 376.234 233.625 375.746C233.55 375.258 233.475 374.769 233.4 374.281C233.062 371.914 233.4 369.922 234.488 368.269C235.576 366.616 237.34 365.527 239.817 365.076C242.332 364.625 244.396 364.963 246.01 366.09ZM237.153 369.697C236.477 370.787 236.289 372.177 236.552 373.905C236.59 374.13 236.627 374.581 236.777 375.22C236.89 375.859 237.003 376.272 237.04 376.497C237.453 378.188 238.128 379.428 239.179 380.179C240.193 380.931 241.431 381.156 242.895 380.893C244.358 380.63 245.409 379.954 246.085 378.864C246.76 377.775 246.948 376.385 246.685 374.656L246.46 373.341L246.197 372.064C245.784 370.373 245.109 369.133 244.058 368.382C243.045 367.631 241.806 367.405 240.343 367.668C238.879 367.931 237.828 368.607 237.153 369.697Z"
            fill="#68035E"
          />
          <path
            d="M228.108 367.631C228.258 367.781 228.371 367.969 228.371 368.194L229.421 384.538C229.421 384.763 229.384 384.951 229.234 385.139C229.084 385.289 228.896 385.402 228.671 385.402L227.207 385.515C226.945 385.515 226.757 385.477 226.607 385.364C226.457 385.252 226.382 385.026 226.344 384.801L226.231 383.298C225.593 384.2 224.843 384.913 223.98 385.439C223.117 385.965 221.991 386.266 220.64 386.341C218.425 386.491 216.661 385.89 215.385 384.538C214.072 383.185 213.359 381.382 213.209 379.052L212.571 369.284C212.571 369.058 212.608 368.87 212.758 368.683C212.908 368.532 213.096 368.42 213.321 368.42L214.898 368.307C215.123 368.307 215.31 368.344 215.498 368.495C215.648 368.645 215.761 368.833 215.761 369.058L216.399 378.601C216.511 380.255 216.999 381.532 217.862 382.396C218.726 383.26 219.889 383.636 221.39 383.561C222.854 383.448 224.017 382.922 224.805 381.945C225.631 380.968 225.969 379.653 225.856 378L225.218 368.457C225.218 368.232 225.256 368.044 225.406 367.856C225.556 367.706 225.744 367.593 225.969 367.593L227.545 367.48C227.77 367.443 227.958 367.518 228.108 367.631Z"
            fill="#68035E"
          />
          <path
            d="M206.303 368.645C207.242 369.171 207.955 369.81 208.48 370.636C209.005 371.463 209.231 372.365 209.193 373.304C209.118 374.807 208.405 376.047 207.129 376.948C205.853 377.85 204.127 378.414 201.95 378.601L196.733 379.052L196.696 380.067C196.583 382.434 197.859 383.711 200.599 383.861C201.65 383.937 202.513 383.749 203.188 383.373C203.864 382.997 204.352 382.509 204.69 381.945C204.802 381.72 204.915 381.57 204.99 381.494C205.102 381.419 205.253 381.382 205.44 381.382L206.791 381.457C207.016 381.457 207.167 381.57 207.317 381.72C207.467 381.87 207.504 382.058 207.504 382.283C207.467 382.809 207.204 383.411 206.679 384.087C206.153 384.763 205.365 385.327 204.277 385.778C203.226 386.228 201.95 386.416 200.449 386.341C197.859 386.191 196.058 385.515 195.007 384.237C193.956 382.997 193.468 381.457 193.581 379.653L194.181 368.42C194.181 368.194 194.294 368.006 194.444 367.856C194.594 367.706 194.782 367.668 195.007 367.668L196.508 367.743C196.733 367.743 196.921 367.856 197.034 368.006C197.146 368.157 197.221 368.344 197.221 368.57L197.146 370.073C197.709 369.359 198.46 368.758 199.473 368.307C200.486 367.856 201.725 367.668 203.226 367.743C204.352 367.856 205.403 368.119 206.303 368.645ZM198.61 371.538C197.596 372.477 197.034 373.905 196.921 375.784L196.883 376.76L200.937 376.385C202.588 376.234 203.864 375.934 204.765 375.408C205.628 374.919 206.116 374.243 206.153 373.417C206.191 372.477 205.853 371.726 205.102 371.162C204.352 370.599 203.451 370.298 202.4 370.261C200.899 370.185 199.623 370.599 198.61 371.538Z"
            fill="#68035E"
          />
          <path
            d="M187.538 372.815L186.15 381.983L188.852 382.396C189.077 382.434 189.265 382.509 189.377 382.697C189.49 382.847 189.527 383.035 189.49 383.26L189.34 384.35C189.302 384.575 189.19 384.763 189.04 384.876C188.889 384.989 188.702 385.064 188.477 385.026L185.775 384.613L184.911 390.324C184.874 390.549 184.761 390.737 184.611 390.85C184.461 390.962 184.273 391.038 184.048 391L182.547 390.775C182.322 390.737 182.134 390.624 182.022 390.474C181.909 390.286 181.834 390.098 181.871 389.873L182.735 384.162L178.494 383.523C178.269 383.486 178.081 383.373 177.968 383.223C177.856 383.035 177.781 382.847 177.818 382.622L177.968 381.532C178.006 381.307 178.118 381.119 178.269 381.006C178.456 380.893 178.644 380.856 178.869 380.893L183.11 381.532L184.461 372.628C184.649 371.463 184.574 370.561 184.311 369.923C184.011 369.284 183.41 368.87 182.509 368.758L180.445 368.457C180.22 368.42 180.032 368.307 179.92 368.157C179.807 367.969 179.77 367.781 179.807 367.555L179.995 366.428C180.032 366.203 180.145 366.015 180.295 365.902C180.445 365.79 180.633 365.714 180.858 365.752L183.185 366.09C186.675 366.579 188.139 368.833 187.538 372.815Z"
            fill="#68035E"
          />
          <path
            d="M175.454 366.466C176.467 368.232 176.692 370.373 176.205 372.853L175.942 374.018L175.642 375.107C174.891 377.474 173.653 379.203 171.926 380.292C170.2 381.382 168.211 381.607 165.921 381.044C163.482 380.405 161.756 379.127 160.817 377.249C159.879 375.333 159.729 373.041 160.442 370.373L160.592 369.772C160.667 369.547 160.78 369.396 160.968 369.284C161.155 369.171 161.343 369.133 161.568 369.209L173.165 372.177L173.24 371.876C173.54 370.411 173.427 369.058 172.864 367.818C172.301 366.579 171.326 365.79 169.975 365.451C168.924 365.188 168.023 365.151 167.273 365.414C166.522 365.639 165.921 365.977 165.471 366.316C165.208 366.541 165.021 366.654 164.871 366.691C164.758 366.729 164.533 366.691 164.27 366.616L162.806 366.24C162.619 366.203 162.431 366.09 162.319 365.94C162.206 365.79 162.168 365.639 162.206 365.451C162.319 364.963 162.769 364.437 163.557 363.911C164.345 363.385 165.358 363.009 166.559 362.784C167.798 362.558 169.111 362.634 170.538 363.009C172.789 363.535 174.441 364.7 175.454 366.466ZM163.219 372.026L163.182 372.139C162.769 373.717 162.882 375.07 163.482 376.272C164.082 377.474 165.096 378.263 166.522 378.639C167.986 379.015 169.224 378.827 170.312 378.038C171.401 377.249 172.114 376.084 172.527 374.544L172.564 374.431L163.219 372.026Z"
            fill="#68035E"
          />
          <path
            d="M150.872 357.111C150.947 357.298 150.947 357.486 150.834 357.712L142.24 378.602C142.165 378.827 142.015 378.977 141.827 379.052C141.64 379.128 141.452 379.128 141.227 379.052L139.613 378.376C139.388 378.263 139.238 378.151 139.163 377.963C139.088 377.775 139.088 377.587 139.2 377.324L147.795 356.434C147.87 356.209 148.02 356.059 148.207 355.983C148.395 355.908 148.62 355.908 148.808 355.983L150.422 356.66C150.647 356.772 150.797 356.923 150.872 357.111Z"
            fill="#68035E"
          />
          <path
            d="M142.916 353.654C142.991 353.842 142.991 354.067 142.878 354.255L133.008 374.957C132.895 375.182 132.745 375.333 132.558 375.408C132.37 375.483 132.145 375.445 131.957 375.333L130.568 374.694C130.343 374.581 130.231 374.431 130.156 374.243C130.081 374.055 130.118 373.83 130.193 373.642L140.063 352.94C140.176 352.715 140.326 352.602 140.514 352.527C140.701 352.452 140.927 352.489 141.114 352.564L142.503 353.203C142.728 353.316 142.841 353.466 142.916 353.654Z"
            fill="#68035E"
          />
          <path
            d="M135.748 350.047C135.823 350.235 135.785 350.461 135.673 350.648L124.751 370.824C124.639 371.05 124.489 371.2 124.264 371.237C124.076 371.275 123.851 371.275 123.663 371.162L122.312 370.448C122.124 370.336 121.974 370.185 121.899 369.998C121.824 369.81 121.862 369.584 121.974 369.396L132.895 349.221C133.008 349.033 133.158 348.883 133.346 348.807C133.533 348.732 133.759 348.77 133.946 348.883L135.297 349.596C135.56 349.709 135.673 349.859 135.748 350.047Z"
            fill="#68035E"
          />
          <path
            d="M126.816 346.891C127.041 348.732 126.478 350.648 125.202 352.602L119.835 360.755C119.722 360.943 119.535 361.056 119.347 361.093C119.122 361.131 118.934 361.093 118.747 360.981L117.433 360.116C117.245 360.004 117.133 359.816 117.095 359.628C117.058 359.403 117.095 359.215 117.208 359.027L122.5 351.024C124.414 348.094 124.113 345.802 121.599 344.111C120.36 343.284 119.122 343.059 117.921 343.36C116.683 343.66 115.632 344.487 114.731 345.877L109.439 353.88C109.327 354.067 109.139 354.18 108.951 354.218C108.726 354.255 108.539 354.218 108.351 354.105L107.037 353.241C106.85 353.128 106.737 352.94 106.7 352.752C106.662 352.527 106.7 352.339 106.812 352.151L115.857 338.475C115.969 338.287 116.157 338.175 116.345 338.137C116.57 338.1 116.758 338.137 116.945 338.25L118.184 339.076C118.371 339.189 118.484 339.377 118.521 339.565C118.559 339.79 118.521 339.978 118.409 340.166L117.546 341.481C118.597 341.105 119.61 340.955 120.623 341.105C121.636 341.218 122.687 341.669 123.851 342.42C125.577 343.547 126.628 345.05 126.816 346.891Z"
            fill="#68035E"
          />
          <path
            d="M110.753 333.929C111.241 335.019 111.504 335.996 111.466 336.86C111.429 337.724 111.278 338.287 111.016 338.626C110.866 338.813 110.678 338.889 110.49 338.889C110.303 338.889 110.115 338.813 109.965 338.701L108.839 337.761C108.726 337.686 108.651 337.574 108.651 337.498C108.614 337.423 108.614 337.273 108.614 337.048C108.614 336.221 108.501 335.432 108.238 334.756C107.976 334.042 107.413 333.328 106.587 332.614C105.649 331.825 104.711 331.337 103.848 331.224C102.984 331.111 102.271 331.374 101.708 332.051C101.333 332.501 101.145 332.952 101.183 333.403C101.22 333.892 101.408 334.455 101.821 335.169C102.196 335.845 102.872 336.822 103.772 338.024C105.011 339.64 105.686 341.068 105.799 342.232C105.912 343.397 105.536 344.487 104.673 345.501C104.11 346.14 103.397 346.628 102.496 346.891C101.596 347.154 100.62 347.192 99.5316 346.929C98.4432 346.666 97.3924 346.065 96.3041 345.201C95.1782 344.261 94.39 343.284 93.9397 342.232C93.4518 341.218 93.2266 340.279 93.2642 339.452C93.3017 338.626 93.4518 338.024 93.7145 337.724C93.8646 337.574 94.0147 337.498 94.2024 337.461C94.39 337.461 94.5777 337.498 94.7278 337.649L95.7411 338.513C96.0038 338.738 96.0789 338.964 96.0413 339.264C96.0413 339.865 96.0789 340.316 96.1539 340.692C96.1915 341.03 96.3791 341.443 96.6418 341.932C96.9045 342.383 97.3549 342.871 97.9178 343.36C98.781 344.073 99.6067 344.449 100.357 344.487C101.108 344.524 101.746 344.224 102.271 343.623C102.572 343.247 102.759 342.871 102.759 342.458C102.797 342.045 102.609 341.481 102.234 340.805C101.858 340.128 101.258 339.227 100.357 338.1C98.9687 336.334 98.2181 334.831 98.0679 333.591C97.9178 332.351 98.2931 331.224 99.1563 330.21C99.7943 329.458 100.582 328.932 101.521 328.707C102.459 328.444 103.51 328.519 104.673 328.895C105.837 329.27 107 329.947 108.163 330.923C109.402 331.75 110.265 332.802 110.753 333.929Z"
            fill="#68035E"
          />
          <path
            d="M94.5026 326.903L87.8974 333.403L89.8114 335.357C89.9615 335.507 90.0365 335.695 90.0365 335.883C90.0365 336.071 89.9615 336.259 89.7738 336.409L88.9857 337.198C88.8356 337.348 88.6479 337.461 88.4228 337.461C88.2351 337.461 88.0475 337.386 87.8598 337.235L85.9458 335.282L81.8175 339.339C81.6674 339.49 81.4798 339.602 81.2546 339.602C81.067 339.602 80.8793 339.527 80.6917 339.377L79.6408 338.287C79.4907 338.137 79.4156 337.949 79.4156 337.724C79.4156 337.498 79.4907 337.311 79.6784 337.16L83.8066 333.103L80.8042 330.059C80.6541 329.909 80.5791 329.721 80.5791 329.496C80.5791 329.27 80.6541 329.082 80.8418 328.932L81.6299 328.143C81.78 327.993 81.9677 327.918 82.1928 327.918C82.3805 327.918 82.5681 328.03 82.7558 328.181L85.7582 331.224L92.1757 324.912C93.0014 324.085 93.5268 323.334 93.6769 322.658C93.827 321.981 93.6019 321.305 92.9639 320.629L91.5002 319.126C91.3501 318.976 91.275 318.788 91.275 318.562C91.275 318.375 91.3876 318.187 91.5377 317.999L92.3634 317.21C92.5135 317.06 92.7012 316.947 92.9263 316.947C93.114 316.947 93.3016 317.022 93.4893 317.172L95.1406 318.863C97.5425 321.418 97.3548 324.085 94.5026 326.903Z"
            fill="#68035E"
          />
          <path
            d="M90.637 314.392C90.637 314.617 90.5244 314.768 90.3367 314.918L77.8394 325.438C77.6517 325.588 77.4641 325.663 77.2389 325.626C77.0137 325.626 76.8261 325.513 76.676 325.325L75.7377 324.198C75.5876 324.01 75.5125 323.785 75.5125 323.597C75.5501 323.372 75.6251 323.184 75.8503 323.033L77.0137 322.057C75.2123 322.357 73.5985 321.606 72.1349 319.877L71.2717 318.825C71.1216 318.638 71.0465 318.45 71.0465 318.224C71.0465 317.999 71.1591 317.849 71.3467 317.698L72.3225 316.872C72.5102 316.721 72.6978 316.646 72.8855 316.646C73.0731 316.646 73.2608 316.759 73.4484 316.947L74.7244 318.45C75.5501 319.427 76.4508 319.953 77.5016 320.028C78.5149 320.103 79.5282 319.727 80.504 318.938L88.3101 312.401C88.4978 312.25 88.6854 312.175 88.9106 312.213C89.1358 312.25 89.3234 312.326 89.4736 312.513L90.4869 313.716C90.5994 313.979 90.6745 314.167 90.637 314.392Z"
            fill="#68035E"
          />
          <path
            d="M81.8926 303.722C82.0428 304.774 81.9677 305.751 81.6299 306.652C81.2922 307.554 80.7292 308.305 79.9411 308.831C78.7026 309.696 77.314 309.883 75.8128 309.395C74.3117 308.907 72.8105 307.892 71.3468 306.314L67.819 302.444L66.9934 303.008C65.0418 304.36 64.8542 306.164 66.3929 308.418C66.9934 309.282 67.6689 309.883 68.382 310.146C69.095 310.447 69.7706 310.56 70.4086 310.447C70.6337 310.409 70.8214 310.372 70.9715 310.409C71.0841 310.447 71.1967 310.56 71.3093 310.71L72.0599 311.837C72.1725 312.025 72.21 312.213 72.1725 312.401C72.1349 312.626 72.0223 312.776 71.8722 312.889C71.4219 313.19 70.7839 313.34 69.9207 313.34C69.0575 313.34 68.1193 313.04 67.106 312.476C66.0927 311.912 65.1544 311.011 64.3288 309.771C62.8651 307.629 62.3022 305.788 62.6775 304.173C63.0152 302.557 63.9535 301.28 65.4171 300.265L74.6494 293.916C74.8371 293.803 75.0247 293.728 75.2499 293.765C75.4751 293.803 75.6252 293.916 75.7753 294.103L76.6385 295.343C76.7511 295.531 76.8261 295.719 76.7511 295.944C76.7135 296.132 76.601 296.32 76.4133 296.433L75.1748 297.297C76.0755 297.297 77.0138 297.522 77.9896 298.048C78.9653 298.574 79.866 299.439 80.7292 300.678C81.3297 301.693 81.705 302.67 81.8926 303.722ZM74.8746 299.363C73.5235 299.138 72.0599 299.551 70.4836 300.603L69.6955 301.167L72.4727 304.173C73.5986 305.412 74.6494 306.239 75.5877 306.615C76.5259 306.99 77.3516 306.953 78.0271 306.502C78.8152 305.976 79.1905 305.225 79.153 304.323C79.1154 303.384 78.8152 302.519 78.2147 301.618C77.3516 300.34 76.2257 299.589 74.8746 299.363Z"
            fill="#68035E"
          />
          <path
            d="M67.1434 291.361L59.037 295.832L60.3506 298.236C60.4631 298.424 60.5007 298.65 60.4256 298.837C60.3506 299.025 60.238 299.176 60.0503 299.288L59.0745 299.814C58.8869 299.927 58.6617 299.965 58.4741 299.927C58.2864 299.889 58.1363 299.739 58.0237 299.551L56.7102 297.147L51.6437 299.889C51.456 300.002 51.2309 300.04 51.0432 300.002C50.8556 299.965 50.7054 299.814 50.5929 299.626L49.8423 298.311C49.7297 298.124 49.6921 297.898 49.7672 297.71C49.8423 297.522 49.9548 297.335 50.1425 297.222L55.209 294.442L53.1449 290.684C53.0323 290.497 52.9947 290.271 53.0698 290.083C53.1449 289.895 53.2575 289.708 53.4451 289.595L54.4209 289.069C54.6085 288.956 54.8337 288.919 55.0213 288.994C55.209 289.069 55.3591 289.182 55.4717 289.407L57.5358 293.164L65.4171 288.806C66.4304 288.242 67.1434 287.641 67.4812 287.003C67.819 286.364 67.7814 285.65 67.3311 284.861L66.3178 283.02C66.2052 282.832 66.1677 282.607 66.2427 282.419C66.3178 282.231 66.4304 282.081 66.6555 281.968L67.6688 281.404C67.8565 281.292 68.0817 281.254 68.2693 281.292C68.457 281.329 68.6071 281.48 68.7197 281.667L69.8456 283.734C71.5719 286.852 70.6712 289.407 67.1434 291.361Z"
            fill="#68035E"
          />
          <path
            d="M64.8165 277.91C64.0284 279.714 62.5647 281.141 60.4256 282.156L59.0745 282.757L57.6859 283.321C55.4716 284.185 53.445 284.222 51.5686 283.508C49.6921 282.795 48.3035 281.292 47.3277 279C46.3519 276.708 46.2393 274.642 47.0275 272.801C47.7781 270.96 49.2417 269.532 51.3809 268.517C51.8313 268.292 52.2816 268.067 52.732 267.879C53.1823 267.691 53.6327 267.503 54.1206 267.353C56.3348 266.489 58.3614 266.451 60.2379 267.127C62.0769 267.841 63.503 269.344 64.5163 271.673C65.4921 274.003 65.6046 276.107 64.8165 277.91ZM59.4498 270.02C58.2488 269.607 56.8227 269.682 55.2089 270.321C54.9838 270.396 54.5709 270.546 53.9705 270.809C53.37 271.072 52.9947 271.26 52.7695 271.373C51.1933 272.124 50.1424 273.064 49.617 274.228C49.0916 275.393 49.1291 276.633 49.6921 278.023C50.255 279.376 51.1557 280.277 52.3567 280.691C53.5576 281.104 54.9838 281.029 56.5975 280.39L57.836 279.902L59.037 279.338C60.6132 278.587 61.664 277.647 62.1894 276.483C62.7149 275.318 62.6773 274.078 62.1144 272.688C61.5514 271.335 60.6507 270.471 59.4498 270.02Z"
            fill="#68035E"
          />
          <path
            d="M59.6 260.59C59.4874 260.778 59.3373 260.89 59.1121 260.966L43.5373 265.85C43.3121 265.925 43.1245 265.887 42.8993 265.812C42.7117 265.7 42.5615 265.549 42.4865 265.324L42.0361 263.934C41.9611 263.708 41.9611 263.483 42.0736 263.295C42.1862 263.107 42.3364 262.994 42.5991 262.882L44.0627 262.431C42.2988 261.98 41.0603 260.703 40.3848 258.561L39.972 257.284C39.8969 257.058 39.8969 256.833 40.0095 256.645C40.0846 256.457 40.2722 256.344 40.4974 256.269L41.6984 255.893C41.9235 255.818 42.1112 255.818 42.2988 255.931C42.4865 256.006 42.5991 256.194 42.6741 256.419L43.2746 258.298C43.6499 259.5 44.2879 260.364 45.2261 260.815C46.1644 261.304 47.2152 261.341 48.4162 260.966L58.1363 257.922C58.3615 257.847 58.5492 257.885 58.7368 257.997C58.9245 258.11 59.0746 258.26 59.1121 258.486L59.5625 259.989C59.7126 260.214 59.7126 260.402 59.6 260.59Z"
            fill="#68035E"
          />
          <path
            d="M46.5022 241.504C45.9392 242.33 45.1511 242.856 44.1378 243.044C43.1245 243.232 42.2238 243.006 41.3982 242.443C40.5725 241.842 40.0471 241.053 39.8594 240.076C39.6718 239.061 39.897 238.16 40.4599 237.333C41.0604 236.507 41.8485 235.981 42.8243 235.83C43.8 235.642 44.7008 235.868 45.5264 236.431C46.3521 236.995 46.8775 237.784 47.0651 238.798C47.2903 239.738 47.1027 240.677 46.5022 241.504Z"
            fill="#68035E"
          />
        </g>
        <g className="symbol">
          <path
            d="M204.179 147.781C204.179 147.781 198.238 144.366 202.933 138.133C207.629 131.9 213.857 127.864 207.293 122.872C200.73 117.881 194.19 123.183 191.363 130.037C188.536 136.891 189.806 143.721 180.128 141.548C170.45 139.374 159.838 143.1 151.717 157.118C143.596 171.113 137.367 171.734 133.295 175.483C129.246 179.208 118.299 193.227 127.665 207.842C132.217 214.935 139.308 217.848 145.44 218.995C150.495 219.926 155.693 219.687 160.676 218.445C167.695 216.702 183.314 213.789 183.553 222.481C183.865 233.682 173.875 238.673 160.46 247.39C147.045 256.082 135.499 266.351 136.433 277.886C137.367 289.421 147.668 300.597 162.041 299.976C176.391 299.355 186.716 286.913 187.961 276.023C189.207 265.133 178.906 269.169 174.858 274.16C170.809 279.152 157.059 285.361 156.436 273.229C155.813 261.097 175.48 255.175 184.847 249.587C194.214 243.975 209.186 230.601 194.525 205.072C194.525 205.072 188.273 192.009 193.902 182.671C199.532 173.334 209.833 167.101 210.144 159.65C210.456 152.199 204.179 147.781 204.179 147.781ZM186.452 168.629C185.518 174.456 180.367 177.489 176.391 181.477C172.414 185.441 167.264 192.678 164.677 195.71C162.089 198.743 155.549 201.537 152.028 198.29C148.507 195.018 148.051 185.441 159.526 173.071C171.001 160.7 178.02 161.632 178.02 161.632C181.541 160.7 187.386 162.802 186.452 168.629Z"
            fill="#99018A"
          />
          <path
            d="M284.478 213.693C282.729 212.141 280.645 210.875 279.256 208.988C276.764 205.669 276.836 201.155 276.764 197.024C276.668 192.272 276.165 187.4 273.938 183.221C271.71 179.041 267.398 175.65 262.654 175.746C258.055 175.841 254.15 178.994 250.653 181.979C246.341 185.656 242.029 189.358 237.717 193.036C236.184 194.349 234.603 195.663 232.686 196.26C230.77 196.857 228.398 196.499 227.224 194.875C226.003 193.227 226.362 190.958 226.865 188.976C229.74 177.943 236.016 168.104 239.969 157.405C246.724 139.16 246.005 117.284 234.315 101.714C232.255 98.9673 229.787 96.3642 226.649 94.9791C223.511 93.594 219.535 93.594 216.852 95.7433C214.169 97.8927 213.402 102.358 215.797 104.842C216.852 105.941 218.313 106.514 219.606 107.302C225.188 110.789 226.961 118.025 227.368 124.568C228.59 144.175 221.547 164.139 208.323 178.683C206.239 180.976 203.963 183.221 202.742 186.062C200.202 192.033 203.005 198.839 206.239 204.451C209.473 210.063 213.306 215.914 212.899 222.386C212.516 228.547 208.251 233.992 207.988 240.177C207.772 245.097 210.192 249.825 213.57 253.432C213.929 253.814 214.288 254.22 214.648 254.602C216.516 256.751 223.966 264.25 235.752 262.435C239.346 261.885 242.556 259.33 246.197 259.497C249.79 259.641 252.976 261.718 256.378 262.96C264.619 265.969 274.321 263.677 280.98 257.993C287.64 252.309 291.401 243.64 291.952 234.924C292.431 227.162 290.299 218.851 284.478 213.693ZM238.651 218.063C240.136 214.792 242.125 211.735 244.448 208.988C246.173 206.935 247.97 204.929 250.317 203.687C253.527 201.991 258.295 202.421 259.9 205.669C261.553 208.988 259.109 212.833 258.774 216.535C258.51 219.52 259.636 222.457 259.804 225.443C260.019 229.646 258.199 233.897 255.013 236.643C251.827 239.389 247.323 240.56 243.203 239.724C239.993 239.079 236.926 236.738 236.519 233.491C235.968 229.096 236.926 223.938 237.525 221.359C237.789 220.26 238.124 219.186 238.603 218.159C238.603 218.135 238.627 218.087 238.651 218.063Z"
            fill="#99018A"
          />
        </g>
      </svg>
    </div>
  );
};

export default LogoMaster;
