import React from "react";

// Styles
import "./index.scss";

const CSDA = () => {
  return (
    <a href="http://www.cssdesignawards.com/" target="_blank">
      <div id="cssda-badge">
        <svg
          version="1.1"
          id="cssda-badge-logo"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 90 90"
          xmlSpace="preserve"
        >
          <path
            d="M32.6,43.6c-0.3,0-0.5,0.1-0.7,0.4c-0.2,0.2-0.3,0.6-0.3,1c0,0.9,0.3,1.3,1,1.3c0.2,0,0.4,0,0.6-0.1
          c0.2-0.1,0.4-0.1,0.6-0.2v1.1c-0.4,0.2-0.8,0.3-1.3,0.3c-0.7,0-1.2-0.2-1.6-0.6c-0.4-0.4-0.6-1-0.6-1.8c0-0.5,0.1-0.9,0.3-1.3
          c0.2-0.4,0.4-0.6,0.8-0.8c0.3-0.2,0.7-0.3,1.2-0.3c0.5,0,1,0.1,1.4,0.3l-0.4,1c-0.2-0.1-0.3-0.1-0.5-0.2
          C33,43.6,32.8,43.6,32.6,43.6z"
          />
          <path
            d="M40,45.8c0,0.3-0.1,0.5-0.2,0.8c-0.1,0.2-0.4,0.4-0.6,0.5c-0.3,0.1-0.6,0.2-1,0.2c-0.3,0-0.6,0-0.8-0.1s-0.4-0.1-0.7-0.2
          v-1.1c0.2,0.1,0.5,0.2,0.8,0.3s0.5,0.1,0.7,0.1c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.1-0.1,0.1-0.3c0-0.1,0-0.1-0.1-0.2
          c0,0-0.1-0.1-0.2-0.1c-0.1,0-0.3-0.1-0.6-0.3c-0.3-0.1-0.5-0.3-0.7-0.4c-0.2-0.1-0.3-0.3-0.3-0.4c-0.1-0.2-0.1-0.4-0.1-0.6
          c0-0.4,0.2-0.8,0.5-1c0.3-0.2,0.7-0.4,1.3-0.4c0.5,0,1,0.1,1.5,0.3l-0.4,1c-0.4-0.2-0.8-0.3-1.1-0.3c-0.2,0-0.3,0-0.4,0.1
          C38,43.7,38,43.8,38,43.8c0,0.1,0,0.2,0.1,0.2c0.1,0.1,0.4,0.2,0.8,0.4c0.4,0.2,0.7,0.4,0.8,0.6C39.9,45.3,40,45.5,40,45.8z"
          />
          <path
            d="M45.9,45.8c0,0.3-0.1,0.5-0.2,0.8c-0.1,0.2-0.4,0.4-0.6,0.5c-0.3,0.1-0.6,0.2-1,0.2c-0.3,0-0.6,0-0.8-0.1
          s-0.4-0.1-0.7-0.2v-1.1c0.2,0.1,0.5,0.2,0.8,0.3s0.5,0.1,0.7,0.1c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.1-0.1,0.1-0.3
          c0-0.1,0-0.1-0.1-0.2c0,0-0.1-0.1-0.2-0.1c-0.1,0-0.3-0.1-0.6-0.3c-0.3-0.1-0.5-0.3-0.7-0.4c-0.2-0.1-0.3-0.3-0.3-0.4
          c-0.1-0.2-0.1-0.4-0.1-0.6c0-0.4,0.2-0.8,0.5-1c0.3-0.2,0.7-0.4,1.3-0.4c0.5,0,1,0.1,1.5,0.3l-0.4,1c-0.4-0.2-0.8-0.3-1.1-0.3
          c-0.2,0-0.3,0-0.4,0.1c-0.1,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.2,0.1,0.2c0.1,0.1,0.4,0.2,0.8,0.4c0.4,0.2,0.7,0.4,0.8,0.6
          C45.9,45.3,45.9,45.5,45.9,45.8z"
          />
          <path
            d="M52.8,44.8c0,0.8-0.2,1.4-0.6,1.8c-0.4,0.4-1,0.6-1.8,0.6h-1.5v-4.7h1.6c0.8,0,1.3,0.2,1.7,0.6S52.8,44.1,52.8,44.8z
           M51.5,44.9c0-0.4-0.1-0.7-0.3-1c-0.2-0.2-0.4-0.3-0.8-0.3h-0.4v2.6h0.3c0.4,0,0.7-0.1,0.8-0.3C51.4,45.7,51.5,45.3,51.5,44.9z"
          />
          <path
            d="M58.7,47.3l-0.2-0.9h-1.5l-0.2,0.9h-1.4l1.5-4.7h1.7l1.5,4.7H58.7z M58.2,45.4L58,44.6c0-0.2-0.1-0.4-0.2-0.7
          c-0.1-0.3-0.1-0.5-0.1-0.6c0,0.1-0.1,0.3-0.1,0.5c-0.1,0.2-0.2,0.7-0.4,1.5H58.2z"
          />
          <polygon points="65.8,39.9 65.8,39.2 51.2,39.2 46.4,34.4 41.7,39.2 39,39.2 49,29.2 38.5,29.2 28.4,39.2 24.6,39.2 24.6,39.9  " />
          <polygon
            points="24.6,49.9 24.6,50.6 39.1,50.6 43.8,55.3 48.6,50.6 51.2,50.6 41.2,60.6 51.7,60.6 57,55.4 61.7,50.6 65.8,50.6
        65.8,49.9"
          />
        </svg>
        <div className="cssda-badge-content">
          <svg
            version="1.1"
            id="cssda-badge-title"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 90 90"
            xmlSpace="preserve"
          >
            <path
              d="M14.3,50.4c0.5-0.1,0.9,0,1.2,0.2c0.3,0.3,0.6,0.7,0.7,1.3c0.1,0.5,0.1,1,0,1.5l-1,0.2c0.1-0.4,0.1-0.7,0.1-1
    c0-0.3,0-0.5-0.1-0.7c-0.1-0.2-0.1-0.4-0.3-0.5c-0.1-0.1-0.3-0.1-0.4-0.1c-0.1,0-0.2,0.1-0.2,0.1s-0.1,0.2-0.2,0.3
    c0,0.1-0.1,0.4-0.2,0.7c-0.1,0.3-0.2,0.6-0.3,0.8s-0.2,0.3-0.4,0.5c-0.2,0.1-0.4,0.2-0.6,0.3c-0.5,0.1-0.8,0-1.2-0.2
    c-0.3-0.2-0.6-0.6-0.7-1.2c-0.1-0.3-0.1-0.5-0.1-0.8c0-0.3,0-0.5,0.1-0.8l0.9,0.2c0,0.3-0.1,0.5-0.1,0.7c0,0.2,0,0.4,0.1,0.5
    c0,0.2,0.1,0.4,0.3,0.4c0.1,0.1,0.3,0.1,0.4,0.1c0.1,0,0.2-0.1,0.2-0.1s0.1-0.1,0.2-0.3c0-0.1,0.1-0.4,0.2-0.8
    c0.1-0.5,0.3-0.9,0.5-1.1S14,50.5,14.3,50.4z"
            />
            <path
              d="M11.7,44.4c0.6,0,1,0.1,1.3,0.5c0.3,0.3,0.5,0.8,0.5,1.5l0,0.5l1.9-0.1l0,1.1l-5.2,0.2l-0.1-1.7c0-0.6,0.1-1.1,0.4-1.5
    S11.1,44.5,11.7,44.4z M12.6,46.9l0-0.4c0-0.3-0.1-0.6-0.2-0.8c-0.1-0.2-0.3-0.2-0.6-0.2c-0.3,0-0.4,0.1-0.6,0.2
    c-0.1,0.1-0.2,0.4-0.2,0.7l0,0.5L12.6,46.9z"
            />
            <path d="M15.9,39.1l-0.4,3l-5.2-0.8l0.4-3l0.9,0.1l-0.3,1.9l1.1,0.2l0.3-1.8l0.9,0.1l-0.3,1.8l1.3,0.2l0.3-1.9L15.9,39.1z" />
            <path
              d="M13.1,33.2c-0.1,0.4-0.1,0.8,0.1,1.1c0.2,0.3,0.6,0.6,1.1,0.8c1.1,0.4,1.8,0.2,2.1-0.7c0.1-0.3,0.2-0.8,0.2-1.4l0.9,0.3
    c0,0.5,0,0.9-0.2,1.4C17,35.5,16.6,36,16,36.2c-0.6,0.2-1.3,0.2-2.1-0.1c-0.5-0.2-0.9-0.4-1.3-0.8s-0.5-0.7-0.6-1.1s0-0.9,0.1-1.3
    c0.2-0.5,0.5-0.9,0.9-1.3l0.7,0.6c-0.2,0.2-0.3,0.3-0.4,0.5S13.1,33.1,13.1,33.2z"
            />
            <path d="M18.7,31.3l-4.6-2.5l0.5-1l4.6,2.5L18.7,31.3z" />
            <path
              d="M22.6,25.6l-1.2-0.5l-1.2,1.5l0.7,1.1l-0.7,0.9l-3-4.7l0.8-1.1l5.3,1.8L22.6,25.6z M20.5,24.8c-1.1-0.4-1.7-0.7-1.9-0.7
    c-0.1-0.1-0.3-0.1-0.3-0.1c0.2,0.3,0.6,0.9,1.4,1.9L20.5,24.8z"
            />
            <path d="M24.8,23.4l-3.4-4l0.8-0.7L25,22l1.6-1.4l0.6,0.7L24.8,23.4z" />
            <path
              d="M35.2,14.3c0.3,0.8,0.3,1.5,0,2.1c-0.3,0.6-0.9,1.1-1.7,1.4l-1.4,0.5l-1.9-4.9l1.5-0.6c0.8-0.3,1.5-0.3,2.1-0.1
    C34.5,13,34.9,13.5,35.2,14.3z M34.1,14.7c-0.4-1-1.1-1.4-2-1l-0.6,0.2l1.2,3.2l0.4-0.2C34.3,16.5,34.6,15.8,34.1,14.7z"
            />
            <path d="M41.4,15.7l-3,0.5L37.6,11l3-0.5l0.2,0.9l-1.9,0.3l0.2,1.1l1.7-0.3l0.2,0.9l-1.7,0.3l0.2,1.3l1.9-0.3L41.4,15.7z" />
            <path
              d="M46.9,14.1c0,0.5-0.2,0.8-0.5,1.1s-0.8,0.4-1.4,0.4c-0.6,0-1.1-0.1-1.5-0.3l0-1c0.4,0.2,0.6,0.3,0.9,0.3
    c0.2,0.1,0.5,0.1,0.7,0.1c0.2,0,0.4,0,0.6-0.1c0.1-0.1,0.2-0.2,0.2-0.4c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.1-0.2-0.2-0.2
    c-0.1-0.1-0.3-0.2-0.7-0.4c-0.3-0.2-0.6-0.3-0.7-0.4c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.4-0.1-0.6c0-0.5,0.2-0.8,0.5-1.1
    c0.3-0.3,0.8-0.4,1.3-0.4c0.3,0,0.5,0,0.8,0.1s0.5,0.2,0.8,0.3l-0.4,0.9c-0.3-0.1-0.5-0.2-0.7-0.3c-0.2,0-0.4-0.1-0.5-0.1
    c-0.2,0-0.4,0-0.5,0.1c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0,0.2,0.1,0.3c0,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.3,0.2,0.7,0.4
    c0.5,0.2,0.8,0.5,1,0.7S46.9,13.8,46.9,14.1z"
            />
            <path d="M49.1,15.9l0.8-5.2l1.1,0.2L50.2,16L49.1,15.9z" />
            <path
              d="M55.6,14.4l2,0.7l-0.9,2.6c-0.4,0-0.7,0-1-0.1c-0.3-0.1-0.6-0.1-0.9-0.2c-0.7-0.3-1.2-0.7-1.5-1.3
    c-0.2-0.6-0.2-1.3,0.1-2.1c0.3-0.8,0.7-1.3,1.3-1.6c0.6-0.3,1.3-0.3,2.1,0c0.5,0.2,1,0.5,1.4,0.8l-0.6,0.7c-0.3-0.3-0.6-0.5-1-0.7
    c-0.5-0.2-0.9-0.1-1.2,0.1c-0.4,0.2-0.7,0.6-0.8,1.1c-0.2,0.5-0.2,1-0.1,1.3c0.1,0.4,0.4,0.6,0.8,0.8c0.2,0.1,0.4,0.1,0.7,0.2
    l0.4-1l-0.9-0.3L55.6,14.4z"
            />
            <path
              d="M62.7,21.4l-1.2-0.8l0.2-4.6l0,0c-0.3,0.6-0.6,1-0.8,1.3l-1.3,2.1l-0.8-0.5l2.8-4.4l1.2,0.8l-0.2,4.5l0,0
    c0.3-0.6,0.6-1,0.7-1.2l1.3-2.1l0.8,0.5L62.7,21.4z"
            />
            <path d="M69.7,29l-0.8-1l0.9-2.4l-0.6-0.2l-1.5,1.1l-0.7-0.9l4.2-3.2l0.7,0.9l-1.9,1.5l0.8,0l2.3,0l0.8,1l-3,0L69.7,29z" />
            <path
              d="M77.2,32.3l-3.1,1.4c-0.4,0.2-0.7,0.2-1,0.2c-0.3,0-0.6-0.2-0.9-0.4c-0.3-0.2-0.5-0.5-0.7-1c-0.3-0.6-0.4-1.2-0.2-1.6
    c0.2-0.5,0.5-0.9,1-1.1l3.1-1.4l0.5,1l-2.9,1.4c-0.4,0.2-0.6,0.4-0.7,0.6c-0.1,0.2-0.1,0.5,0.1,0.8c0.2,0.3,0.4,0.5,0.6,0.6
    c0.2,0.1,0.5,0,0.9-0.2l2.9-1.4L77.2,32.3z"
            />
            <path
              d="M76.7,40.5c-0.8,0.2-1.5,0.1-2.1-0.3s-0.9-1-1.1-1.9l-0.3-1.5l5.1-1.1l0.3,1.6c0.2,0.8,0.1,1.5-0.2,2.1
    C78.1,40,77.5,40.4,76.7,40.5z M76.5,39.4c1.1-0.2,1.5-0.8,1.3-1.8L77.7,37l-3.3,0.7l0.1,0.5C74.7,39.3,75.4,39.7,76.5,39.4z"
            />
            <path
              d="M76.9,48.2c-0.9,0-1.5-0.2-2-0.7s-0.7-1.1-0.7-1.9s0.3-1.4,0.7-1.8c0.5-0.4,1.1-0.6,2-0.6c0.9,0,1.5,0.2,2,0.7
    c0.5,0.4,0.7,1.1,0.7,1.9c0,0.8-0.3,1.4-0.7,1.8C78.4,48,77.7,48.2,76.9,48.2z M76.9,44.4c-0.6,0-1,0.1-1.3,0.3s-0.5,0.5-0.5,1
    c0,0.9,0.6,1.3,1.7,1.4c1.2,0,1.8-0.4,1.8-1.3c0-0.4-0.1-0.8-0.4-1C78,44.5,77.5,44.4,76.9,44.4z"
            />
            <path
              d="M74.6,53.8c-0.5-0.1-0.8-0.4-1-0.8s-0.2-0.9-0.1-1.5c0.1-0.5,0.3-1,0.6-1.4l1,0.2c-0.2,0.3-0.4,0.6-0.5,0.8
    c-0.1,0.2-0.2,0.4-0.2,0.6c-0.1,0.2-0.1,0.4,0,0.6c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0,0.2,0,0.3,0c0.1,0,0.2-0.1,0.3-0.2
    c0.1-0.1,0.3-0.3,0.5-0.6c0.2-0.3,0.4-0.5,0.6-0.6c0.2-0.1,0.4-0.2,0.6-0.3c0.2-0.1,0.4,0,0.7,0c0.5,0.1,0.8,0.3,1,0.7
    s0.2,0.8,0.1,1.4c-0.1,0.3-0.2,0.5-0.3,0.7c-0.1,0.2-0.3,0.5-0.4,0.7L77.2,54c0.2-0.2,0.3-0.5,0.4-0.6c0.1-0.2,0.1-0.3,0.2-0.5
    c0-0.2,0-0.4,0-0.5c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1,0-0.2,0-0.3,0s-0.2,0.1-0.3,0.2s-0.3,0.3-0.5,0.6c-0.3,0.4-0.6,0.7-0.9,0.8
    C75.2,53.8,74.9,53.8,74.6,53.8z"
            />
          </svg>
          <svg
            version="1.1"
            id="cssda-badge-award"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 90 90"
            xmlSpace="preserve"
          >
            <path
              d="M23.4,72l0.6-1.3l-1.5-1.4l-1.2,0.7l-1-0.9l5.3-2.9l1.1,1l-2.3,5.6L23.4,72z M24.4,69.8c0.5-1.2,0.8-1.8,0.9-2
          c0.1-0.2,0.1-0.3,0.2-0.4c-0.3,0.2-1,0.6-2.2,1.3L24.4,69.8z"
            />
            <path
              d="M32,77.2l-1.2-0.6l0.6-3c0-0.1,0.1-0.3,0.2-0.7c0.1-0.3,0.2-0.5,0.2-0.7c-0.1,0.1-0.2,0.3-0.4,0.6c-0.2,0.2-0.3,0.4-0.4,0.5
          l-2,2.3l-1.2-0.6l1.2-5.7l1.1,0.5L29.3,73c-0.1,0.6-0.3,1.1-0.4,1.5c0.1-0.1,0.2-0.3,0.4-0.6s0.3-0.4,0.5-0.6l2.2-2.5l1,0.5
          l-0.7,3.2c0,0.1-0.1,0.4-0.2,0.6c-0.1,0.3-0.2,0.5-0.2,0.7c0.1-0.2,0.3-0.4,0.4-0.6c0.2-0.2,0.3-0.4,0.5-0.6l2-2.4l1.1,0.5L32,77.2z
          "
            />
            <path
              d="M39.9,79.3l-0.1-1.4l-2-0.4L37,78.8l-1.3-0.2l3-5.2l1.4,0.3l0.9,6L39.9,79.3z M39.6,76.9c-0.1-1.3-0.2-2-0.2-2.2
          c0-0.2,0-0.3,0-0.4c-0.1,0.3-0.5,1.1-1.2,2.3L39.6,76.9z"
            />
            <path
              d="M45.4,77.5l0.1,2.2l-1.2,0l-0.2-5.7l1.6-0.1c0.8,0,1.3,0.1,1.7,0.4c0.4,0.3,0.6,0.7,0.6,1.3c0,0.3-0.1,0.6-0.2,0.9
          c-0.2,0.3-0.4,0.5-0.8,0.6c0.9,1.2,1.5,2,1.8,2.4l-1.3,0.1L46,77.5L45.4,77.5z M45.4,76.5l0.4,0c0.4,0,0.7-0.1,0.8-0.2
          c0.2-0.1,0.3-0.3,0.2-0.6c0-0.3-0.1-0.5-0.3-0.6S46,75,45.7,75l-0.4,0L45.4,76.5z"
            />
            <path
              d="M55.7,75c0.3,0.9,0.2,1.7-0.2,2.3c-0.4,0.6-1,1.1-2,1.3L51.9,79l-1.5-5.5l1.7-0.5c0.9-0.3,1.6-0.2,2.3,0.1
          C55,73.5,55.4,74.1,55.7,75z M54.5,75.3c-0.3-1.2-1-1.6-2-1.3l-0.6,0.2l1,3.6l0.5-0.1C54.4,77.3,54.8,76.5,54.5,75.3z"
            />
            <path d="M62.6,74.7l-2.9,1.6l-2.7-5l2.9-1.6l0.5,0.9l-1.8,1l0.6,1.1l1.7-0.9l0.5,0.9l-1.7,0.9l0.7,1.3l1.8-1L62.6,74.7z" />
            <path
              d="M67.1,67.5c0.6,0.7,0.9,1.4,0.8,2.1c-0.1,0.7-0.5,1.4-1.2,2.1l-1.2,1.1l-3.7-4.3l1.3-1.2c0.7-0.6,1.4-0.9,2.1-0.9
          C65.9,66.5,66.6,66.8,67.1,67.5z M66.2,68.3c-0.8-0.9-1.6-1-2.4-0.3l-0.5,0.4l2.4,2.8l0.4-0.3C67,70.1,67,69.3,66.2,68.3z"
            />
          </svg>
        </div>
      </div>
    </a>
  );
};

export default CSDA;
