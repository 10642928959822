// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-illustrations-js": () => import("./../../../src/pages/illustrations.js" /* webpackChunkName: "component---src-pages-illustrations-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-blueberry-js": () => import("./../../../src/pages/projects/blueberry.js" /* webpackChunkName: "component---src-pages-projects-blueberry-js" */),
  "component---src-pages-projects-boavista-js": () => import("./../../../src/pages/projects/boavista.js" /* webpackChunkName: "component---src-pages-projects-boavista-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-iq-js": () => import("./../../../src/pages/projects/iq.js" /* webpackChunkName: "component---src-pages-projects-iq-js" */),
  "component---src-pages-projects-ramengo-js": () => import("./../../../src/pages/projects/ramengo.js" /* webpackChunkName: "component---src-pages-projects-ramengo-js" */)
}

